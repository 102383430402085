import { connect } from 'react-redux'
import Staffs from './Staffs'
import {
  fetchStaffs,
  createStaff,
  updateStaff,
  deleteStaff,
} from './staffs.api'

const mapStateToProps = (state) => ({
  staffs: state.staffs.staffs,
  loading: state.staffs.loading,
  error: state.staffs.error,
})

const mapDispatchToProps = (dispatch) => ({
  fetchStaffs: () => dispatch(fetchStaffs()),
  createStaff: (staffData) => dispatch(createStaff(staffData)),
  updateStaff: (staffData) => dispatch(updateStaff(staffData)),
  deleteStaff: (staffId) => dispatch(deleteStaff(staffId)),
})

export const StaffsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Staffs)
