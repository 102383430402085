// Action Creators
export const FETCH_CAMPUSES_REQUEST = 'FETCH_CAMPUSES_REQUEST'
export const FETCH_CAMPUSES_SUCCESS = 'FETCH_CAMPUSES_SUCCESS'
export const FETCH_CAMPUSES_FAILURE = 'FETCH_CAMPUSES_FAILURE'

export const CREATE_CAMPUS_REQUEST = 'CREATE_CAMPUS_REQUEST'
export const CREATE_CAMPUS_SUCCESS = 'CREATE_CAMPUS_SUCCESS'
export const CREATE_CAMPUS_FAILURE = 'CREATE_CAMPUS_FAILURE'

export const UPDATE_CAMPUS_REQUEST = 'UPDATE_CAMPUS_REQUEST'
export const UPDATE_CAMPUS_SUCCESS = 'UPDATE_CAMPUS_SUCCESS'
export const UPDATE_CAMPUS_FAILURE = 'UPDATE_CAMPUS_FAILURE'

export const DELETE_CAMPUS_REQUEST = 'DELETE_CAMPUS_REQUEST'
export const DELETE_CAMPUS_SUCCESS = 'DELETE_CAMPUS_SUCCESS'
export const DELETE_CAMPUS_FAILURE = 'DELETE_CAMPUS_FAILURE'

export const fetchCampusesRequest = () => ({
  type: FETCH_CAMPUSES_REQUEST,
})

export const fetchCampusesSuccess = (campuses) => ({
  type: FETCH_CAMPUSES_SUCCESS,
  payload: campuses,
})

export const fetchCampusesFailure = (error) => ({
  type: FETCH_CAMPUSES_FAILURE,
  payload: error,
})

export const createCampusRequest = (campus) => ({
  type: CREATE_CAMPUS_REQUEST,
  payload: campus,
})

export const createCampusSuccess = (campus) => ({
  type: CREATE_CAMPUS_SUCCESS,
  payload: campus,
})

export const createCampusFailure = (error) => ({
  type: CREATE_CAMPUS_FAILURE,
  payload: error,
})

export const updateCampusRequest = (campus) => ({
  type: UPDATE_CAMPUS_REQUEST,
  payload: campus,
})

export const updateCampusSuccess = (campus) => ({
  type: UPDATE_CAMPUS_SUCCESS,
  payload: campus,
})

export const updateCampusFailure = (error) => ({
  type: UPDATE_CAMPUS_FAILURE,
  payload: error,
})

export const deleteCampusRequest = (campusId) => ({
  type: DELETE_CAMPUS_REQUEST,
  payload: campusId,
})

export const deleteCampusSuccess = (campusId) => ({
  type: DELETE_CAMPUS_SUCCESS,
  payload: campusId,
})

export const deleteCampusFailure = (error) => ({
  type: DELETE_CAMPUS_FAILURE,
  payload: error,
})
