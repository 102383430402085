import { connect } from 'react-redux'
import Modules from './Modules'
import {
  fetchModules,
  createModule,
  updateModule,
  deleteModule,
} from './modules.api'

const mapStateToProps = (state) => ({
  modules: state.modules.modules,
  loading: state.modules.loading,
  error: state.modules.error,
})

const mapDispatchToProps = (dispatch) => ({
  fetchModules: (courseId) => dispatch(fetchModules(courseId)),
  createModule: (moduleData) => dispatch(createModule(moduleData)),
  updateModule: (moduleData) => dispatch(updateModule(moduleData)),
  deleteModule: (moduleId) => dispatch(deleteModule(moduleId)),
})

export const ModulesContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Modules)
