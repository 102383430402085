// Action Creators

// Action Types
export const FETCH_STAFFS_REQUEST = 'FETCH_STAFFS_REQUEST'
export const FETCH_STAFFS_SUCCESS = 'FETCH_STAFFS_SUCCESS'
export const FETCH_STAFFS_FAILURE = 'FETCH_STAFFS_FAILURE'

export const CREATE_STAFF_REQUEST = 'CREATE_STAFF_REQUEST'
export const CREATE_STAFF_SUCCESS = 'CREATE_STAFF_SUCCESS'
export const CREATE_STAFF_FAILURE = 'CREATE_STAFF_FAILURE'

export const UPDATE_STAFF_REQUEST = 'UPDATE_STAFF_REQUEST'
export const UPDATE_STAFF_SUCCESS = 'UPDATE_STAFF_SUCCESS'
export const UPDATE_STAFF_FAILURE = 'UPDATE_STAFF_FAILURE'

export const DELETE_STAFF_REQUEST = 'DELETE_STAFF_REQUEST'
export const DELETE_STAFF_SUCCESS = 'DELETE_STAFF_SUCCESS'
export const DELETE_STAFF_FAILURE = 'DELETE_STAFF_FAILURE'

export const SEARCH_STAFFS_SUCCESS = 'SEARCH_STAFFS_SUCCESS'
export const SEARCH_STAFFS_FAILURE = 'SEARCH_STAFFS_FAILURE'

export const fetchStaffsRequest = () => ({
  type: FETCH_STAFFS_REQUEST,
})

export const fetchStaffsSuccess = (staffs) => ({
  type: FETCH_STAFFS_SUCCESS,
  payload: staffs,
})

export const fetchStaffsFailure = (error) => ({
  type: FETCH_STAFFS_FAILURE,
  payload: error,
})

export const createStaffRequest = (staff) => ({
  type: CREATE_STAFF_REQUEST,
  payload: staff,
})

export const createStaffSuccess = (staff) => ({
  type: CREATE_STAFF_SUCCESS,
  payload: staff,
})

export const createStaffFailure = (error) => ({
  type: CREATE_STAFF_FAILURE,
  payload: error,
})

export const updateStaffRequest = (staff) => ({
  type: UPDATE_STAFF_REQUEST,
  payload: staff,
})

export const updateStaffSuccess = (staff) => ({
  type: UPDATE_STAFF_SUCCESS,
  payload: staff,
})

export const updateStaffFailure = (error) => ({
  type: UPDATE_STAFF_FAILURE,
  payload: error,
})

export const deleteStaffRequest = (staffId) => ({
  type: DELETE_STAFF_REQUEST,
  payload: staffId,
})

export const deleteStaffSuccess = (staffId) => ({
  type: DELETE_STAFF_SUCCESS,
  payload: staffId,
})

export const deleteStaffFailure = (error) => ({
  type: DELETE_STAFF_FAILURE,
  payload: error,
})

export const searchStaffsSuccess = (searchedStaffs) => ({
  type: SEARCH_STAFFS_SUCCESS,
  payload: searchedStaffs,
})

export const searchStaffsFailure = (error) => ({
  type: SEARCH_STAFFS_FAILURE,
  payload: error,
})
