import React from 'react'
import { Form, Input, Button } from 'antd'
import PropTypes from 'prop-types'

// Constants for password validation
const PASSWORD_MIN_LENGTH = 8
const PASSWORD_COMPLEXITY_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/

const AddAdminForm = ({ onSubmit, form }) => {
  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item
        name="first_name"
        label="First Name"
        rules={[{ required: true, message: 'Please enter first name' }]}
      >
        <Input placeholder="First Name" />
      </Form.Item>
      <Form.Item
        name="last_name"
        label="Last Name"
        rules={[{ required: true, message: 'Please enter last name' }]}
      >
        <Input placeholder="Last Name" />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[
          { required: true, message: 'Please enter email' },
          { type: 'email', message: 'Please enter a valid email' },
        ]}
      >
        <Input placeholder="Email" />
      </Form.Item>
      <Form.Item
        name="password"
        label="Password"
        rules={[
          { required: true, message: 'Please enter a password' },
          {
            min: PASSWORD_MIN_LENGTH,
            message: `Password must be at least ${PASSWORD_MIN_LENGTH} characters long`,
          },
          {
            pattern: PASSWORD_COMPLEXITY_REGEX,
            message:
              'Password must contain at least one lowercase letter, one uppercase letter, and one digit',
          },
        ]}
      >
        <Input.Password placeholder="Password" />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ backgroundColor: '#005d41', color: 'white' }}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}

AddAdminForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
}

export default AddAdminForm
