import { connect } from 'react-redux'
import Students from './Students'
import {
  fetchStudents,
  createStudent,
  updateStudent,
  deleteStudent,
} from './students.api'

const mapStateToProps = (state) => ({
  students: state.students.students,
  loading: state.students.loading,
  error: state.students.error,
})

const mapDispatchToProps = (dispatch) => ({
  fetchStudents: () => dispatch(fetchStudents()),
  createStudent: (studentData) => dispatch(createStudent(studentData)),
  updateStudent: (studentData) => dispatch(updateStudent(studentData)),
  deleteStudent: (studentId) => dispatch(deleteStudent(studentId)),
})

export const StudentsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Students)
