/* eslint-disable */
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Select, Checkbox, Button } from 'antd'
import { searchStudents } from '../../Students/students.api'
import { addStudentToIntake } from '../intakes.api'

const { Option } = Select

const AddStudentForm = ({
  intakeId,
  intakeDetails,
  onClose,
  refreshIntakeDetails,
}) => {
  const dispatch = useDispatch()
  const { searchedStudents } = useSelector((state) => state.students)
  const [selectedStudent, setSelectedStudent] = useState(null)
  const [form] = Form.useForm()

  const onSearch = (value) => {
    if (value) {
      dispatch(searchStudents(value))
    }
  }

  const onSubmit = (values) => {
    if (selectedStudent) {
      dispatch(
        addStudentToIntake(intakeId, selectedStudent, values.courses),
      ).then(() => {
        refreshIntakeDetails() // Fetch updated intake details
        onClose() // Close the drawer
      })
    }
  }

  return (
    <Form layout="vertical" form={form} onFinish={onSubmit}>
      <Form.Item
        name="search"
        label="Search Student"
        rules={[
          {
            required: true,
            message: 'Please search and select a student.',
          },
        ]}
      >
        <Select
          showSearch
          placeholder="Select a student"
          onSearch={onSearch}
          optionFilterProp="children"
          notFoundContent={null}
          onChange={(value) => setSelectedStudent(value)}
          value={selectedStudent}
        >
          {searchedStudents?.map((student) => (
            <Option key={student.id} value={student.id}>
              {student.first_name} {student.last_name} ({student.email})
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="courses" label="Select Courses">
        <Checkbox.Group>
          {/* eslint-disable-next-line react/prop-types */}
          {intakeDetails?.courses?.map((course) => (
            <Checkbox key={course.id} value={course.id}>
              {course.name}
            </Checkbox>
          ))}
        </Checkbox.Group>
      </Form.Item>
      <Form.Item>
        <Button
          style={{ backgroundColor: '#005d41', color: 'white' }}
          type="primary"
          htmlType="submit"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}

export default AddStudentForm
