// Action Types
export const FETCH_MODULES_REQUEST = 'FETCH_MODULES_REQUEST'
export const FETCH_MODULES_SUCCESS = 'FETCH_MODULES_SUCCESS'
export const FETCH_MODULES_FAILURE = 'FETCH_MODULES_FAILURE'

export const FETCH_MODULE_DETAILS_REQUEST = 'FETCH_MODULE_DETAILS_REQUEST'
export const FETCH_MODULE_DETAILS_SUCCESS = 'FETCH_MODULE_DETAILS_SUCCESS'
export const FETCH_MODULE_DETAILS_FAILURE = 'FETCH_MODULE_DETAILS_FAILURE'

export const CREATE_MODULE_REQUEST = 'CREATE_MODULE_REQUEST'
export const CREATE_MODULE_SUCCESS = 'CREATE_MODULE_SUCCESS'
export const CREATE_MODULE_FAILURE = 'CREATE_MODULE_FAILURE'

export const UPDATE_MODULE_REQUEST = 'UPDATE_MODULE_REQUEST'
export const UPDATE_MODULE_SUCCESS = 'UPDATE_MODULE_SUCCESS'
export const UPDATE_MODULE_FAILURE = 'UPDATE_MODULE_FAILURE'

export const DELETE_MODULE_REQUEST = 'DELETE_MODULE_REQUEST'
export const DELETE_MODULE_SUCCESS = 'DELETE_MODULE_SUCCESS'
export const DELETE_MODULE_FAILURE = 'DELETE_MODULE_FAILURE'

export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST'
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS'
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE'

export const UPLOAD_ASSIGNMENT_REQUEST = 'UPLOAD_ASSIGNMENT_REQUEST'
export const UPLOAD_ASSIGNMENT_SUCCESS = 'UPLOAD_ASSIGNMENT_SUCCESS'
export const UPLOAD_ASSIGNMENT_FAILURE = 'UPLOAD_ASSIGNMENT_FAILURE'

export const DELETE_FILE_REQUEST = 'DELETE_FILE_REQUEST'
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS'
export const DELETE_FILE_FAILURE = 'DELETE_FILE_FAILURE'

export const FETCH_SUBMISSIONS_REQUEST = 'FETCH_SUBMISSIONS_REQUEST'
export const FETCH_SUBMISSIONS_SUCCESS = 'FETCH_SUBMISSIONS_SUCCESS'
export const FETCH_SUBMISSIONS_FAILURE = 'FETCH_SUBMISSIONS_FAILURE'

export const PROVIDE_ASSIGNMENT_FEEDBACK_REQUEST =
  'PROVIDE_ASSIGNMENT_FEEDBACK_REQUEST'
export const PROVIDE_ASSIGNMENT_FEEDBACK_SUCCESS =
  'PROVIDE_ASSIGNMENT_FEEDBACK_SUCCESS'
export const PROVIDE_ASSIGNMENT_FEEDBACK_FAILURE =
  'PROVIDE_ASSIGNMENT_FEEDBACK_FAILURE'

export const TOGGLE_UPLOAD_REQUEST = 'TOGGLE_UPLOAD_REQUEST'
export const TOGGLE_UPLOAD_SUCCESS = 'TOGGLE_UPLOAD_SUCCESS'
export const TOGGLE_UPLOAD_FAILURE = 'TOGGLE_UPLOAD_FAILURE'

export const uploadAssignmentRequest = () => ({
  type: UPLOAD_ASSIGNMENT_REQUEST,
})

export const uploadAssignmentSuccess = (data) => ({
  type: UPLOAD_ASSIGNMENT_SUCCESS,
  payload: data,
})

export const uploadAssignmentFailure = (error) => ({
  type: UPLOAD_ASSIGNMENT_FAILURE,
  payload: error,
})

// Action Creators

export const fetchModulesRequest = () => ({
  type: FETCH_MODULES_REQUEST,
})

export const fetchModulesSuccess = (modules) => ({
  type: FETCH_MODULES_SUCCESS,
  payload: modules,
})

export const fetchModulesFailure = (error) => ({
  type: FETCH_MODULES_FAILURE,
  payload: error,
})

export const fetchModuleDetailsRequest = () => ({
  type: FETCH_MODULE_DETAILS_REQUEST,
})

export const fetchModuleDetailsSuccess = (moduleDetails) => ({
  type: FETCH_MODULE_DETAILS_SUCCESS,
  payload: moduleDetails,
})

export const fetchModuleDetailsFailure = (error) => ({
  type: FETCH_MODULE_DETAILS_FAILURE,
  payload: error,
})

export const createModuleRequest = (module) => ({
  type: CREATE_MODULE_REQUEST,
  payload: module,
})

export const createModuleSuccess = (module) => ({
  type: CREATE_MODULE_SUCCESS,
  payload: module,
})

export const createModuleFailure = (error) => ({
  type: CREATE_MODULE_FAILURE,
  payload: error,
})

export const updateModuleRequest = (module) => ({
  type: UPDATE_MODULE_REQUEST,
  payload: module,
})

export const updateModuleSuccess = (module) => ({
  type: UPDATE_MODULE_SUCCESS,
  payload: module,
})

export const updateModuleFailure = (error) => ({
  type: UPDATE_MODULE_FAILURE,
  payload: error,
})

export const deleteModuleRequest = (moduleId) => ({
  type: DELETE_MODULE_REQUEST,
  payload: moduleId,
})

export const deleteModuleSuccess = (moduleId) => ({
  type: DELETE_MODULE_SUCCESS,
  payload: moduleId,
})

export const deleteModuleFailure = (error) => ({
  type: DELETE_MODULE_FAILURE,
  payload: error,
})

export const deleteFileRequest = (fileId) => ({
  type: DELETE_FILE_REQUEST,
  payload: fileId,
})

export const deleteFileSuccess = (fileId) => ({
  type: DELETE_FILE_SUCCESS,
  payload: fileId,
})

export const deleteFileFailure = (error) => ({
  type: DELETE_FILE_FAILURE,
  payload: error,
})

export const uploadFilesRequest = (data) => ({
  type: UPLOAD_FILE_REQUEST,
  payload: data,
})

export const uploadFilesSuccess = (data) => ({
  type: UPLOAD_FILE_SUCCESS,
  payload: data,
})

export const uploadFilesFailure = (error) => ({
  type: UPLOAD_FILE_FAILURE,
  payload: error,
})

export const provideAssignmentFeedbackRequest = () => ({
  type: PROVIDE_ASSIGNMENT_FEEDBACK_REQUEST,
})

export const provideAssignmentFeedbackSuccess = (feedbackData) => ({
  type: PROVIDE_ASSIGNMENT_FEEDBACK_SUCCESS,
  payload: feedbackData,
})

export const provideAssignmentFeedbackFailure = (error) => ({
  type: PROVIDE_ASSIGNMENT_FEEDBACK_FAILURE,
  payload: error,
})

export const toggleUploadRequest = () => ({
  type: TOGGLE_UPLOAD_REQUEST,
})

export const toggleUploadSuccess = (toggleUpload) => ({
  type: TOGGLE_UPLOAD_SUCCESS,
  payload: toggleUpload,
})

export const toggleUploadFailure = (error) => ({
  type: TOGGLE_UPLOAD_FAILURE,
  payload: error,
})

export const fetchSubmissionsRequest = () => ({
  type: FETCH_SUBMISSIONS_REQUEST,
})

export const fetchSubmissionsSuccess = (submission) => ({
  type: FETCH_SUBMISSIONS_SUCCESS,
  payload: submission,
})

export const fetchSubmissionsFailure = (error) => ({
  type: FETCH_SUBMISSIONS_FAILURE,
  payload: error,
})
