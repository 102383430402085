import React, { useEffect, useState } from 'react'
import {
  Table,
  Spin,
  Alert,
  Button,
  Space,
  Drawer,
  Tooltip,
  Form,
  Input, // Import Input component for search
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
  fetchIntakes,
  deleteIntake,
  fetchStudentIntakes,
  searchIntakes, // Import the searchIntakes API
} from './intakes.api'
import { format } from 'date-fns'
import AddIntakePage from './Form/AddIntakePage'
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  UnlockOutlined,
} from '@ant-design/icons'

const { Search } = Input // Destructure Search from Input

const Intakes = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loading, intakes, error, searchedIntakes } = useSelector(
    (state) => state.intakes,
  )
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [hoveredRowId, setHoveredRowId] = useState(null)
  const { studentId } = useParams()
  const [editMode, setEditMode] = useState(false)
  const [searchQuery, setSearchQuery] = useState('') // State to store search query

  const statusIcons = {
    open: <UnlockOutlined style={{ color: '#52c41a' }} />, // Green icon for "Open"
    closed: <CheckCircleOutlined style={{ color: '#f5222d' }} />, // Red icon for "Closed"
    pending: <ClockCircleOutlined style={{ color: '#faad14' }} />, // Yellow icon for "Pending"
  }

  useEffect(() => {
    if (!studentId) {
      dispatch(fetchIntakes())
    }
  }, [dispatch])

  const handleDelete = (id) => {
    dispatch(deleteIntake(id))
  }

  useEffect(() => {
    if (studentId) {
      dispatch(fetchStudentIntakes(studentId))
    }
  }, [dispatch, studentId])

  // Handle search input change
  const handleSearch = (value) => {
    setSearchQuery(value) // Update search query state
    if (value) {
      dispatch(searchIntakes(value)) // Dispatch searchIntakes API call
    }
  }

  const columns = [
    {
      title: 'Intakes Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => navigate(`/intakes/intakeDetails/${record.id}`)}
          style={{ padding: 0 }}
        >
          {text}
        </Button>
      ),
    },
    {
      title: 'Intake Code',
      dataIndex: 'intake_code',
      key: 'intake_code',
    },
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'start_date',
      render: (date) => format(new Date(date), 'yyyy-MM-dd'),
    },
    {
      title: 'Valid Till',
      dataIndex: 'available_till_date',
      key: 'available_till_date',
      render: (date) => format(new Date(date), 'yyyy-MM-dd'),
    },
    { title: 'Campus', dataIndex: ['campus', 'name'], key: 'campus' },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tooltip title={status.charAt(0).toUpperCase() + status.slice(1)}>
          {statusIcons[status] || status}
        </Tooltip>
      ),
    },
    { title: 'Capacity', dataIndex: 'capacity', key: 'capacity' },
    !studentId && {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="danger"
            style={{ backgroundColor: '#cb0000', color: 'white' }}
            onClick={() => handleDelete(record.id)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ].filter(Boolean)

  if (loading) {
    return <Spin tip="Loading intakes..." />
  }

  if (error) {
    return (
      <Alert
        message="Error"
        description={error.message}
        type="error"
        showIcon
      />
    )
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 16, // Add some spacing between the header and the table
        }}
      >
        <h2>Intakes</h2>
        <div style={{ display: 'flex', gap: '16px' }}>
          {/* Add Search Input */}
          <Search
            placeholder="Search Intakes"
            allowClear
            enterButton
            style={{ width: 300 }}
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
          />
          {!studentId && (
            <Button
              style={{ backgroundColor: '#005d41', color: 'white' }}
              type="primary"
              onClick={() => setDrawerVisible(true)}
            >
              Add Intake
            </Button>
          )}
        </div>
      </div>

      {/* Use searchedIntakes if available, otherwise use intakes */}
      <Table
        columns={columns}
        dataSource={searchQuery ? searchedIntakes : intakes} // Use searchedIntakes when searchQuery is not empty
        rowKey="id"
        locale={{ emptyText: 'No Data' }}
        onRow={(record) => ({
          onMouseEnter: () => setHoveredRowId(record.id),
          onMouseLeave: () => setHoveredRowId(null),
          style: {
            backgroundColor: hoveredRowId === record.id ? '#e6f7ff' : 'white',
          },
        })}
      />

      <Drawer
        title={editMode ? 'Edit Intake' : 'Add Intake'}
        width={500}
        onClose={() => {
          setDrawerVisible(false)
          setEditMode(false)
        }}
        visible={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <AddIntakePage
          setDrawerVisible={() => setDrawerVisible(false)}
          editMode={editMode}
          form={form}
        />
      </Drawer>
    </div>
  )
}

export default Intakes
