import React, { useEffect } from 'react'
import { Descriptions, Spin, Alert, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { fetchStudentDetails } from './students.api'
import { useParams } from 'react-router-dom'

// eslint-disable-next-line react/prop-types
const StudentDetails = () => {
  const dispatch = useDispatch()
  const { studentId } = useParams()

  const { loading, studentDetails, error } = useSelector(
    (state) => state.students,
  )

  useEffect(() => {
    if (studentId) {
      dispatch(fetchStudentDetails(studentId))
    }
  }, [dispatch, studentId])

  if (loading) {
    return <Spin tip="Loading student details..." />
  }

  if (error) {
    return (
      <Alert
        message="Error"
        description={error.message}
        type="error"
        showIcon
      />
    )
  }

  // Function to render intake information with tooltip
  const renderIntakes = (intakes) => {
    if (!intakes || intakes.length === 0) {
      return 'No Intakes'
    }
    return intakes.map((intake, index) => (
      <div key={intake.id}>
        <Tooltip
          title={`${intake.name} - ${intake.description} (Campus: ${intake.campus?.name})`}
        >
          {`${intake.name} (Campus: ${intake.campus?.name})`}
        </Tooltip>
        {index < intakes.length - 1 && <br />}{' '}
        {/* Add line breaks between intakes */}
      </div>
    ))
  }

  return (
    <Descriptions title="Student Details" bordered layout="vertical">
      <Descriptions.Item label="First Name">
        {studentDetails.first_name || '-'}
      </Descriptions.Item>
      <Descriptions.Item label="Last Name">
        {studentDetails.last_name || '-'}
      </Descriptions.Item>
      <Descriptions.Item label="Email">
        {studentDetails.email || '-'}
      </Descriptions.Item>
      <Descriptions.Item label="Phone">
        {studentDetails.phone || '-'}
      </Descriptions.Item>
      <Descriptions.Item label="Date of Birth">
        {studentDetails.dob || '-'}
      </Descriptions.Item>
      <Descriptions.Item label="Gender">
        {studentDetails.gender || '-'}
      </Descriptions.Item>
      <Descriptions.Item label="Address Line 1">
        {studentDetails.address_line1 || '-'}
      </Descriptions.Item>
      <Descriptions.Item label="Address Line 2">
        {studentDetails.address_line2 || '-'}
      </Descriptions.Item>
      <Descriptions.Item label="City">
        {studentDetails.city || '-'}
      </Descriptions.Item>
      <Descriptions.Item label="Postal Address">
        {studentDetails.postal_address || '-'}
      </Descriptions.Item>
      <Descriptions.Item label="Postcode">
        {studentDetails.postcode || '-'}
      </Descriptions.Item>
      <Descriptions.Item label="Nationality">
        {studentDetails.nationality || '-'}
      </Descriptions.Item>
      <Descriptions.Item label="Birth Country">
        {studentDetails.birth_country || '-'}
      </Descriptions.Item>
      <Descriptions.Item label="Ethnicity">
        {studentDetails.ethnicity || '-'}
      </Descriptions.Item>
      <Descriptions.Item label="Passport ID">
        {studentDetails.passport_id || '-'}
      </Descriptions.Item>
      <Descriptions.Item label="Marital Status">
        {studentDetails.marital_status || '-'}
      </Descriptions.Item>
      <Descriptions.Item label="Preferred Contact">
        {studentDetails.preferred_contact || '-'}
      </Descriptions.Item>
      <Descriptions.Item label="Special Need Required">
        {studentDetails.special_need_required ? 'Yes' : 'No'}
      </Descriptions.Item>
      {studentDetails.special_need_required && (
        <Descriptions.Item label="Special Need Details">
          {studentDetails.special_need_details || '-'}
        </Descriptions.Item>
      )}
      <Descriptions.Item label="Next of Kin">
        {studentDetails.next_of_kin || '-'}
      </Descriptions.Item>
      <Descriptions.Item label="Associated Intakes">
        {renderIntakes(studentDetails.intakes)}
      </Descriptions.Item>
    </Descriptions>
  )
}

export default StudentDetails
