import React, { useEffect, useState } from 'react'
import { Table, Spin, Alert, Button, Space, Drawer, Form, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchStaffs,
  createStaff,
  updateStaff,
  deleteStaff,
} from './staffs.api'
import AddStaffForm from './Form/AddStaffForm'

const Staffs = () => {
  const dispatch = useDispatch()
  const { loading, staffs, error } = useSelector((state) => state.staffs)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [currentStaff, setCurrentStaff] = useState(null)
  const [form] = Form.useForm()

  useEffect(() => {
    dispatch(fetchStaffs())
  }, [dispatch])

  const handleDelete = (id) => {
    dispatch(deleteStaff(id))
  }

  const handleAddStaff = (values) => {
    dispatch(createStaff(values)).then(() => {
      setDrawerVisible(false)
      form.resetFields()
      dispatch(fetchStaffs())
    })
  }

  const handleEditStaff = (values) => {
    if (currentStaff) {
      dispatch(updateStaff({ id: currentStaff.id, values })).then(() => {
        setDrawerVisible(false)
        setIsEditing(false)
        setCurrentStaff(null)
        form.resetFields()
        dispatch(fetchStaffs())
      })
    }
  }

  const handleEditClick = (staff) => {
    setCurrentStaff(staff)
    form.setFieldsValue(staff)
    setIsEditing(true)
    setDrawerVisible(true)
  }

  // Truncate long content with ellipsis and show full content on hover
  const truncateText = (text, maxLength = 50) => {
    if (text.length > maxLength) {
      return `${text.slice(0, maxLength)}...`
    }
    return text
  }

  const getIntakeInfo = (intakes) => {
    if (!intakes || intakes.length === 0) return 'N/A'
    const intakeText = intakes
      .map((intake) => `${intake.name} (${intake.campus?.name})`)
      .join(', ')

    return <Tooltip title={intakeText}>{truncateText(intakeText, 50)}</Tooltip>
  }

  const getCourseInfo = (intakes) => {
    if (!intakes || intakes.length === 0) return 'N/A'
    const courseText = intakes
      .map((intake) => intake.courses.map((course) => course.name).join(', '))
      .join(', ')

    return <Tooltip title={courseText}>{truncateText(courseText, 50)}</Tooltip>
  }

  const columns = [
    { title: 'First Name', dataIndex: 'first_name', key: 'first_name' },
    { title: 'Last Name', dataIndex: 'last_name', key: 'last_name' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    {
      title: 'Intake',
      key: 'intake',
      render: (_, record) => getIntakeInfo(record.intakes),
    },
    {
      title: 'Courses',
      key: 'courses',
      render: (_, record) => getCourseInfo(record.intakes),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button
            style={{
              backgroundColor: '#005d41',
              color: 'white',
            }}
            type="primary"
            onClick={() => handleEditClick(record)}
          >
            Edit
          </Button>
          <Button
            type="danger"
            style={{ backgroundColor: '#cb0000', color: 'white' }}
            onClick={() => handleDelete(record.id)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ]

  if (loading) {
    return <Spin tip="Loading staffs..." />
  }

  if (error) {
    return (
      <Alert
        message="Error"
        description={error.message}
        type="error"
        showIcon
      />
    )
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h2>Staffs</h2>
        <Button
          type="primary"
          style={{
            backgroundColor: '#005d41',
            color: 'white',
          }}
          onClick={() => {
            setIsEditing(false)
            setCurrentStaff(null)
            form.resetFields()
            setDrawerVisible(true)
          }}
        >
          Add Staff
        </Button>
      </div>
      <Table columns={columns} dataSource={staffs} rowKey="id" />

      <Drawer
        title={isEditing ? 'Edit Staff' : 'Add Staff'}
        width={320}
        onClose={() => {
          setDrawerVisible(false)
          setIsEditing(false)
          setCurrentStaff(null)
          form.resetFields()
        }}
        visible={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <AddStaffForm
          onSubmit={isEditing ? handleEditStaff : handleAddStaff}
          form={form}
        />
      </Drawer>
    </div>
  )
}

export default Staffs
