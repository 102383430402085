// Action Creators

// Action Types
export const FETCH_INTAKES_REQUEST = 'FETCH_INTAKES_REQUEST'
export const FETCH_INTAKES_SUCCESS = 'FETCH_INTAKES_SUCCESS'
export const FETCH_INTAKES_FAILURE = 'FETCH_INTAKES_FAILURE'

export const CREATE_INTAKE_REQUEST = 'CREATE_INTAKE_REQUEST'
export const CREATE_INTAKE_SUCCESS = 'CREATE_INTAKE_SUCCESS'
export const CREATE_INTAKE_FAILURE = 'CREATE_INTAKE_FAILURE'

export const UPDATE_INTAKE_REQUEST = 'UPDATE_INTAKE_REQUEST'
export const UPDATE_INTAKE_SUCCESS = 'UPDATE_INTAKE_SUCCESS'
export const UPDATE_INTAKE_FAILURE = 'UPDATE_INTAKE_FAILURE'

export const DELETE_INTAKE_REQUEST = 'DELETE_INTAKE_REQUEST'
export const DELETE_INTAKE_SUCCESS = 'DELETE_INTAKE_SUCCESS'
export const DELETE_INTAKE_FAILURE = 'DELETE_INTAKE_FAILURE'

export const FETCH_INTAKE_DETAILS_REQUEST = 'FETCH_INTAKE_DETAILS_REQUEST'
export const FETCH_INTAKE_DETAILS_SUCCESS = 'FETCH_INTAKE_DETAILS_SUCCESS'
export const FETCH_INTAKE_DETAILS_FAILURE = 'FETCH_INTAKE_DETAILS_FAILURE'

export const ADD_STUDENT_TO_INTAKE_REQUEST = 'ADD_STUDENT_TO_INTAKE_REQUEST'
export const ADD_STUDENT_TO_INTAKE_SUCCESS = 'ADD_STUDENT_TO_INTAKE_SUCCESS'
export const ADD_STUDENT_TO_INTAKE_FAILURE = 'ADD_STUDENT_TO_INTAKE_FAILURE'

export const ADD_STAFF_TO_INTAKE_REQUEST = 'ADD_STAFF_TO_INTAKE_REQUEST'
export const ADD_STAFF_TO_INTAKE_SUCCESS = 'ADD_STAFF_TO_INTAKE_SUCCESS'
export const ADD_STAFF_TO_INTAKE_FAILURE = 'ADD_STAFF_TO_INTAKE_FAILURE'

export const FETCH_STUDENT_INTAKES_REQUEST = 'FETCH_STUDENT_INTAKES_REQUEST'
export const FETCH_STUDENT_INTAKES_SUCCESS = 'FETCH_STUDENT_INTAKES_SUCCESS'
export const FETCH_STUDENT_INTAKES_FAILURE = 'FETCH_STUDENT_INTAKES_FAILURE'

export const SEARCH_INTAKES_SUCCESS = 'SEARCH_INTAKES_SUCCESS'
export const SEARCH_INTAKES_FAILURE = 'SEARCH_INTAKES_FAILURE'

export const DELETE_STUDENT_FROM_INTAKE_REQUEST =
  'DELETE_STUDENT_FROM_INTAKE_REQUEST'
export const DELETE_STUDENT_FROM_INTAKE_SUCCESS =
  'DELETE_STUDENT_FROM_INTAKE_SUCCESS'
export const DELETE_STUDENT_FROM_INTAKE_FAILURE =
  'DELETE_STUDENT_FROM_INTAKE_FAILURE'

export const DELETE_STAFF_FROM_INTAKE_REQUEST =
  'DELETE_STAFF_FROM_INTAKE_REQUEST'
export const DELETE_STAFF_FROM_INTAKE_SUCCESS =
  'DELETE_STAFF_FROM_INTAKE_SUCCESS'
export const DELETE_STAFF_FROM_INTAKE_FAILURE =
  'DELETE_STAFF_FROM_INTAKE_FAILURE'

export const searchIntakesSuccess = (searchedIntakes) => ({
  type: SEARCH_INTAKES_SUCCESS,
  payload: searchedIntakes,
})

export const searchIntakesFailure = (error) => ({
  type: SEARCH_INTAKES_FAILURE,
  payload: error,
})

export const fetchIntakeDetailsRequest = () => ({
  type: FETCH_INTAKE_DETAILS_REQUEST,
})

export const fetchIntakeDetailsSuccess = (intake) => ({
  type: FETCH_INTAKE_DETAILS_SUCCESS,
  payload: intake,
})

export const fetchIntakeDetailsFailure = (error) => ({
  type: FETCH_INTAKE_DETAILS_FAILURE,
  payload: error,
})

export const fetchIntakesRequest = () => ({
  type: FETCH_INTAKES_REQUEST,
})

export const fetchIntakesSuccess = (intakes) => ({
  type: FETCH_INTAKES_SUCCESS,
  payload: intakes,
})

export const fetchIntakesFailure = (error) => ({
  type: FETCH_INTAKES_FAILURE,
  payload: error,
})

export const createIntakeRequest = (intake) => ({
  type: CREATE_INTAKE_REQUEST,
  payload: intake,
})

export const createIntakeSuccess = (intake) => ({
  type: CREATE_INTAKE_SUCCESS,
  payload: intake,
})

export const createIntakeFailure = (error) => ({
  type: CREATE_INTAKE_FAILURE,
  payload: error,
})

export const updateIntakeRequest = (intake) => ({
  type: UPDATE_INTAKE_REQUEST,
  payload: intake,
})

export const updateIntakeSuccess = (intake) => ({
  type: UPDATE_INTAKE_SUCCESS,
  payload: intake,
})

export const updateIntakeFailure = (error) => ({
  type: UPDATE_INTAKE_FAILURE,
  payload: error,
})

export const deleteIntakeRequest = (intakeId) => ({
  type: DELETE_INTAKE_REQUEST,
  payload: intakeId,
})

export const deleteIntakeSuccess = (intakeId) => ({
  type: DELETE_INTAKE_SUCCESS,
  payload: intakeId,
})

export const deleteIntakeFailure = (error) => ({
  type: DELETE_INTAKE_FAILURE,
  payload: error,
})

export const deleteStudentFromIntakeRequest = (studentId) => ({
  type: DELETE_STUDENT_FROM_INTAKE_REQUEST,
  payload: studentId,
})

export const deleteStudentFromIntakeSuccess = (studentId) => ({
  type: DELETE_STUDENT_FROM_INTAKE_SUCCESS,
  payload: studentId,
})

export const deleteStudentFromIntakeFailure = (error) => ({
  type: DELETE_STUDENT_FROM_INTAKE_FAILURE,
  payload: error,
})

export const deleteStaffFromIntakeRequest = (staffId) => ({
  type: DELETE_STAFF_FROM_INTAKE_REQUEST,
  payload: staffId,
})

export const deleteStaffFromIntakeSuccess = (staffId) => ({
  type: DELETE_STAFF_FROM_INTAKE_SUCCESS,
  payload: staffId,
})

export const deleteStaffFromIntakeFailure = (error) => ({
  type: DELETE_STAFF_FROM_INTAKE_FAILURE,
  payload: error,
})

export const addStudentToIntakeRequest = (student) => ({
  type: ADD_STUDENT_TO_INTAKE_REQUEST,
  payload: student,
})

export const addStudentToIntakeSuccess = (student) => ({
  type: ADD_STUDENT_TO_INTAKE_SUCCESS,
  payload: student,
})

export const addStudentToIntakeFailure = (error) => ({
  type: ADD_STUDENT_TO_INTAKE_FAILURE,
  payload: error,
})

export const addStaffToIntakeRequest = (staff) => ({
  type: ADD_STAFF_TO_INTAKE_REQUEST,
  payload: staff,
})

export const addStaffToIntakeSuccess = (staff) => ({
  type: ADD_STAFF_TO_INTAKE_SUCCESS,
  payload: staff,
})

export const addStaffToIntakeFailure = (error) => ({
  type: ADD_STAFF_TO_INTAKE_FAILURE,
  payload: error,
})

// Request action
export const fetchStudentIntakesRequest = () => ({
  type: FETCH_STUDENT_INTAKES_REQUEST,
})

// Success action
export const fetchStudentIntakesSuccess = (intakes) => ({
  type: FETCH_STUDENT_INTAKES_SUCCESS,
  payload: intakes,
})

// Failure action
export const fetchStudentIntakesFailure = (error) => ({
  type: FETCH_STUDENT_INTAKES_FAILURE,
  payload: error,
})
