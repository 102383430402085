// modules.reducer.js

import {
  FETCH_MODULES_REQUEST,
  FETCH_MODULES_SUCCESS,
  FETCH_MODULES_FAILURE,
  CREATE_MODULE_REQUEST,
  CREATE_MODULE_SUCCESS,
  CREATE_MODULE_FAILURE,
  UPDATE_MODULE_REQUEST,
  UPDATE_MODULE_SUCCESS,
  UPDATE_MODULE_FAILURE,
  DELETE_MODULE_REQUEST,
  DELETE_MODULE_SUCCESS,
  DELETE_MODULE_FAILURE,
  FETCH_MODULE_DETAILS_REQUEST,
  FETCH_MODULE_DETAILS_SUCCESS,
  FETCH_MODULE_DETAILS_FAILURE,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  UPLOAD_ASSIGNMENT_REQUEST,
  UPLOAD_ASSIGNMENT_SUCCESS,
  UPLOAD_ASSIGNMENT_FAILURE,
  TOGGLE_UPLOAD_REQUEST,
  TOGGLE_UPLOAD_SUCCESS,
  TOGGLE_UPLOAD_FAILURE,
  DELETE_FILE_FAILURE,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_REQUEST,
  FETCH_SUBMISSIONS_FAILURE,
  FETCH_SUBMISSIONS_SUCCESS,
  FETCH_SUBMISSIONS_REQUEST,
} from './modules.actions'

const initialState = {
  modules: [],
  loading: false,
  error: null,
  moduleDetails: [],
  fetchedSubmissions: [],
}

const modulesReducer = (state = initialState, action) => {
  let updatedAssignments
  switch (action.type) {
    case FETCH_MODULES_REQUEST:
    case FETCH_MODULE_DETAILS_REQUEST:
    case FETCH_SUBMISSIONS_REQUEST:
    case CREATE_MODULE_REQUEST:
    case UPDATE_MODULE_REQUEST:
    case DELETE_MODULE_REQUEST:
    case DELETE_FILE_REQUEST:
    case UPLOAD_FILE_REQUEST:
    case TOGGLE_UPLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case UPLOAD_ASSIGNMENT_REQUEST:
      return {
        ...state,
        uploadingAssignment: true,
        uploadAssignmentError: null,
      }
    case UPLOAD_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        uploadingAssignment: false,
        uploadedAssignment: action.payload,
      }
    case FETCH_SUBMISSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchedSubmissions: action.payload,
      }
    case UPLOAD_ASSIGNMENT_FAILURE:
      return {
        ...state,
        uploadingAssignment: false,
        uploadAssignmentError: action.payload,
      }
    case FETCH_MODULES_SUCCESS:
      return {
        ...state,
        loading: false,
        modules: action.payload,
        error: null,
      }
    case FETCH_MODULE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        moduleDetails: action.payload,
        error: null,
      }
    case CREATE_MODULE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        modules: [...state.modules, action.payload],
      }
    case UPDATE_MODULE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        modules: state.modules.map((module) =>
          module.id === action.payload.id ? action.payload : module,
        ),
      }
    case DELETE_MODULE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        modules: state.modules.filter((module) => module.id !== action.payload),
      }
    case DELETE_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        modules: state.modules.filter((module) => module.id !== action.payload),
      }
    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        moduleDetails: {
          ...state.moduleDetails,
          files: [
            // eslint-disable-next-line no-unsafe-optional-chaining
            ...state?.moduleDetails?.files,
            {
              file_path: action.payload.file_path,
              file_name: action.payload.file_name,
              file_type: action.payload.file_type,
            },
          ],
        },
      }
    case FETCH_MODULES_FAILURE:
    case CREATE_MODULE_FAILURE:
    case UPDATE_MODULE_FAILURE:
    case DELETE_MODULE_FAILURE:
    case FETCH_MODULE_DETAILS_FAILURE:
    case UPLOAD_FILE_FAILURE:
    case FETCH_SUBMISSIONS_FAILURE:
    case DELETE_FILE_FAILURE:
    case TOGGLE_UPLOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case 'PROVIDE_ASSIGNMENT_FEEDBACK_REQUEST':
      return {
        ...state,
        feedbackLoading: true,
        feedbackError: null,
      }
    case 'PROVIDE_ASSIGNMENT_FEEDBACK_SUCCESS':
      // Update assignment feedback in state
      updatedAssignments = state.moduleDetails.files.map((file) => {
        if (file.id === action.payload.assignmentId) {
          return {
            ...file,
            feedback: action.payload.feedback,
          }
        }
        return file
      })
      return {
        ...state,
        moduleDetails: {
          ...state.moduleDetails,
          files: updatedAssignments,
        },
        feedbackLoading: false,
        feedbackError: null,
      }
    case 'PROVIDE_ASSIGNMENT_FEEDBACK_FAILURE':
      return {
        ...state,
        feedbackLoading: false,
        feedbackError: action.payload,
      }
    case TOGGLE_UPLOAD_SUCCESS:
      // Update the allowUpload field in the state
      updatedAssignments = state?.moduleDetails?.files?.map((file) => {
        if (file.studentUploadAssignments) {
          return {
            ...file,
            studentUploadAssignments: file.studentUploadAssignments.map(
              (assignment) =>
                assignment.id === action.payload.id
                  ? action.payload
                  : assignment,
            ),
          }
        }
        return file
      })
      return {
        ...state,
        loading: false,
        moduleDetails: {
          ...state.moduleDetails,
          files: updatedAssignments,
        },
        error: null,
      }
    default:
      return state
  }
}

export default modulesReducer
