import React, { useMemo } from 'react'
import { Form, Input, Button, Select, DatePicker, Radio } from 'antd'
import countryList from 'react-select-country-list'
import PropTypes from 'prop-types'

const { Option } = Select

// Constants for password validation
const PASSWORD_MIN_LENGTH = 8
const PASSWORD_COMPLEXITY_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/

const AddStudentForm = ({ onSubmit, form, isEditing }) => {
  // Custom list of nationalities
  const nationalityOptions = [
    { label: 'British', value: 'british' },
    { label: 'American', value: 'american' },
    { label: 'Canadian', value: 'canadian' },
    { label: 'Australian', value: 'australian' },
    { label: 'Indian', value: 'indian' },
    { label: 'Chinese', value: 'chinese' },
    { label: 'Nigerian', value: 'nigerian' },
    { label: 'Mexican', value: 'mexican' },
    { label: 'German', value: 'german' },
    { label: 'French', value: 'french' },
    { label: 'Brazilian', value: 'brazilian' },
    { label: 'Japanese', value: 'japanese' },
    { label: 'South African', value: 'south_african' },
    { label: 'Russian', value: 'russian' },
    { label: 'Italian', value: 'italian' },
    { label: 'Spanish', value: 'spanish' },
    { label: 'Irish', value: 'irish' },
    { label: 'Bangladeshi', value: 'bangladeshi' },
    { label: 'Pakistani', value: 'pakistani' },
    { label: 'Filipino', value: 'filipino' },
    { label: 'Romanian', value: 'romanian' }, // Added Romanian
    { label: 'Thai', value: 'thai' }, // Example additional nationality
    { label: 'Vietnamese', value: 'vietnamese' }, // Example additional nationality
    // Add more nationalities as needed
  ]

  const countryOptions = useMemo(() => countryList().getData(), [])

  // Ethnicity options based on your provided list
  const ethnicityOptions = [
    { label: 'Asian or Asian British', value: 'Asian or Asian British' },
    { label: 'Indian', value: 'Indian' },
    { label: 'Pakistani', value: 'Pakistani' },
    { label: 'Bangladeshi', value: 'Bangladeshi' },
    { label: 'Chinese', value: 'Chinese' },
    {
      label: 'Any other Asian background',
      value: 'Any other Asian background',
    },
    {
      label: 'Black, Black British, Caribbean or African',
      value: 'Black, Black British, Caribbean or African',
    },
    { label: 'African', value: 'African' },
    { label: 'Caribbean', value: 'Caribbean' },
    {
      label: 'Any other Black, Black British, or Caribbean background',
      value: 'Any other Black, Black British, or Caribbean background',
    },
    {
      label: 'Mixed or Multiple ethnic groups',
      value: 'Mixed or Multiple ethnic groups',
    },
    { label: 'White and Black Caribbean', value: 'White and Black Caribbean' },
    { label: 'White and Black African', value: 'White and Black African' },
    { label: 'White and Asian', value: 'White and Asian' },
    {
      label: 'Any other Mixed or Multiple ethnic background',
      value: 'Any other Mixed or Multiple ethnic background',
    },
    { label: 'White', value: 'White' },
    {
      label: 'English, Welsh, Scottish, Northern Irish, or British',
      value: 'English, Welsh, Scottish, Northern Irish, or British',
    },
    { label: 'Irish', value: 'Irish' },
    { label: 'Gypsy or Irish Traveller', value: 'Gypsy or Irish Traveller' },
    { label: 'Roma', value: 'Roma' },
    {
      label: 'Any other White background',
      value: 'Any other White background',
    },
    { label: 'Other ethnic group', value: 'Other ethnic group' },
    { label: 'Arab', value: 'Arab' },
    { label: 'Any other ethnic group', value: 'Any other ethnic group' },
  ]

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item name="student_title" label="Title">
        <Select placeholder="Select Title">
          <Option value="Mr">Mr</Option>
          <Option value="Mrs">Mrs</Option>
          <Option value="Miss">Miss</Option>
          <Option value="Dr">Dr</Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="first_name"
        label="First Name"
        rules={[{ required: true, message: 'Please enter first name' }]}
      >
        <Input placeholder="First Name" />
      </Form.Item>

      <Form.Item
        name="last_name"
        label="Last Name"
        rules={[{ required: true, message: 'Please enter last name' }]}
      >
        <Input placeholder="Last Name" />
      </Form.Item>

      <Form.Item
        name="email"
        label="Email"
        rules={[
          { required: true, message: 'Please enter email' },
          { type: 'email', message: 'Please enter a valid email' },
        ]}
      >
        <Input placeholder="Email" />
      </Form.Item>

      {!isEditing && (
        <Form.Item
          name="password"
          label="Password"
          rules={[
            { required: true, message: 'Please enter a password' },
            {
              min: PASSWORD_MIN_LENGTH,
              message: `Password must be at least ${PASSWORD_MIN_LENGTH} characters long`,
            },
            {
              pattern: PASSWORD_COMPLEXITY_REGEX,
              message:
                'Password must contain at least one lowercase letter, one uppercase letter, and one digit',
            },
          ]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>
      )}

      <Form.Item
        name="dob"
        label="Date of Birth"
        rules={[{ required: true, message: 'Please select date of birth' }]}
      >
        <DatePicker
          format="YYYY-MM-DD"
          style={{ width: '100%' }}
          placeholder="Select Date of Birth"
          disabledTime={() => true} // Ensure time picker is disabled
        />
      </Form.Item>

      <Form.Item name="gender" label="Gender">
        <Radio.Group>
          <Radio value="MALE">Male</Radio>
          <Radio value="FEMALE">Female</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item name="phone" label="Phone">
        <Input placeholder="Phone Number" />
      </Form.Item>

      <Form.Item name="address_line1" label="Address Line 1">
        <Input placeholder="Address Line 1" />
      </Form.Item>

      <Form.Item name="address_line2" label="Address Line 2">
        <Input placeholder="Address Line 2" />
      </Form.Item>

      <Form.Item name="postal_address" label="Postal Address">
        <Input placeholder="Postal Address" />
      </Form.Item>

      <Form.Item name="education" label="Education">
        <Input.TextArea placeholder="Education details" />
      </Form.Item>

      <Form.Item name="nationality" label="Nationality">
        <Select
          placeholder="Select Nationality"
          options={nationalityOptions}
          showSearch
        />
      </Form.Item>

      <Form.Item name="birth_country" label="Country of Birth">
        <Select
          placeholder="Select Birth Country"
          options={countryOptions}
          showSearch
        />
      </Form.Item>

      <Form.Item name="ethnicity" label="Ethnicity">
        <Select placeholder="Select Ethnicity" options={ethnicityOptions} />
      </Form.Item>

      <Form.Item name="city" label="City">
        <Input placeholder="City" />
      </Form.Item>

      <Form.Item name="postcode" label="Postcode">
        <Input placeholder="Postcode" />
      </Form.Item>

      <Form.Item name="passport_id" label="Passport ID">
        <Input placeholder="Passport ID" />
      </Form.Item>

      <Form.Item name="marital_status" label="Marital Status">
        <Select placeholder="Select Marital Status">
          <Option value="MARRIED">Married</Option>
          <Option value="UNMARRIED">Unmarried</Option>
          <Option value="PREFERNOTTOSAY">Prefer not to say</Option>
          <Option value="NOTKNOWN">Not Known</Option>
        </Select>
      </Form.Item>

      <Form.Item name="preferred_contact" label="Preferred Contact">
        <Select placeholder="Select Preferred Contact">
          <Option value="ALL">All</Option>
          <Option value="EMAIL">Email</Option>
          <Option value="TELEPHONE">Telephone</Option>
          <Option value="TEXT">Text</Option>
        </Select>
      </Form.Item>

      <Form.Item name="special_need_required" label="Special Need Required">
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item name="special_need_details" label="Special Need Details">
        <Input.TextArea placeholder="Details of any special needs" />
      </Form.Item>

      <Form.Item name="next_of_kin" label="Next of Kin">
        <Input placeholder="Next of Kin" />
      </Form.Item>

      <Form.Item>
        <Button
          style={{
            backgroundColor: '#005d41',
            color: 'white',
          }}
          type="primary"
          htmlType="submit"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}

AddStudentForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
}

export default AddStudentForm
