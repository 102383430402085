import React, { useEffect, useState } from 'react'
import {
  Table,
  Spin,
  Alert,
  Button,
  Space,
  Drawer,
  Form,
  Tooltip,
  Input,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  fetchStudents,
  createStudent,
  updateStudent,
  deleteStudent,
  searchStudents, // Import the searchStudents function
} from './students.api'
import AddStudentForm from './Form/AddStudentForm'
import dayjs from 'dayjs'

const Students = () => {
  const dispatch = useDispatch()
  const { loading, searchedStudents, students, error } = useSelector(
    (state) => state.students,
  )
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [currentStudent, setCurrentStudent] = useState(null)
  const [form] = Form.useForm()
  const [searchQuery, setSearchQuery] = useState('') // State for search query
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(fetchStudents())
  }, [dispatch])

  useEffect(() => {
    if (searchQuery) {
      dispatch(searchStudents(searchQuery)) // Dispatch searchStudents when searchQuery changes
    } else {
      dispatch(fetchStudents()) // Fetch all students if searchQuery is empty
    }
  }, [dispatch, searchQuery])

  const handleDelete = (id) => {
    dispatch(deleteStudent(id)).then(() => {
      dispatch(fetchStudents())
    })
  }

  const handleAddStudent = (values) => {
    if (values.dob) {
      values.dob = values.dob.format('YYYY-MM-DD')
    }
    values.special_need_required =
      values.special_need_required === 'yes' ? false : true // Simplified logic
    dispatch(createStudent(values)).then(() => {
      setDrawerVisible(false)
      form.resetFields()
      dispatch(fetchStudents())
    })
  }

  const handleEditStudent = (values) => {
    if (currentStudent) {
      dispatch(updateStudent({ id: currentStudent.id, values })).then(() => {
        setDrawerVisible(false)
        setIsEditing(false)
        setCurrentStudent(null)
        form.resetFields()
        dispatch(fetchStudents())
      })
    }
  }

  const handleEditClick = (student) => {
    setCurrentStudent(student)

    // Set all form fields using the student object
    form.setFieldsValue({
      student_title: student.student_title,
      first_name: student.first_name,
      last_name: student.last_name,
      email: student.email,
      dob: student.dob ? dayjs(student.dob) : null, // Convert to dayjs for DatePicker
      gender: student.gender,
      phone: student.phone,
      address_line1: student.address_line1,
      address_line2: student.address_line2,
      postal_address: student.postal_address,
      education: student.education,
      nationality: student.nationality,
      birth_country: student.birth_country,
      ethnicity: student.ethnicity,
      city: student.city,
      postcode: student.postcode,
      passport_id: student.passport_id,
      marital_status: student.marital_status,
      preferred_contact: student.preferred_contact,
      special_need_required: student.special_need_required,
      special_need_details: student.special_need_details,
      next_of_kin: student.next_of_kin,
    })

    setIsEditing(true)
    setDrawerVisible(true)
  }

  // Truncate long content with ellipsis and show full content on hover
  const truncateText = (text, maxLength = 50) => {
    if (text.length > maxLength) {
      return `${text.slice(0, maxLength)}...`
    }
    return text
  }

  const getIntakeInfo = (intakes) => {
    if (!intakes || intakes.length === 0) return 'N/A'
    const intakeText = intakes
      .map((intake) => `${intake.name} (${intake.campus?.name})`)
      .join(', ')

    return <Tooltip title={intakeText}>{truncateText(intakeText, 50)}</Tooltip>
  }

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
      render: (text, record) => (
        <Button type="link" onClick={() => navigate(`/students/${record.id}`)}>
          {text}
        </Button>
      ),
    },
    { title: 'Last Name', dataIndex: 'last_name', key: 'last_name' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    {
      title: 'Intake',
      key: 'intake',
      render: (_, record) => getIntakeInfo(record.intakes),
    },
    {
      title: 'Date of Birth',
      key: 'dob',
      dataIndex: 'dob',
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
    },
    {
      title: 'Phone',
      key: 'phone',
      dataIndex: 'phone',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button
            style={{
              backgroundColor: '#005d41',
              color: 'white',
            }}
            type="primary"
            onClick={() => handleEditClick(record)}
          >
            Edit
          </Button>
          <Button
            type="danger"
            style={{ backgroundColor: '#cb0000', color: 'white' }}
            onClick={() => handleDelete(record.id)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ]

  if (loading) {
    return <Spin tip="Loading students..." />
  }

  if (error) {
    return (
      <Alert
        message="Error"
        description={error.message}
        type="error"
        showIcon
      />
    )
  }

  const studentOptions = searchQuery ? searchedStudents : students

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h2>Students</h2>
        <Button
          type="primary"
          style={{
            backgroundColor: '#005d41',
            color: 'white',
          }}
          onClick={() => {
            setIsEditing(false)
            setCurrentStudent(null)
            form.resetFields()
            setDrawerVisible(true)
          }}
        >
          Add Student
        </Button>
      </div>
      {/* Search Input */}
      <Input
        placeholder="Search students..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{ marginBottom: 16 }}
      />
      <Table columns={columns} dataSource={studentOptions} rowKey="id" />

      <Drawer
        title={isEditing ? 'Edit Student' : 'Add Student'}
        width={700}
        onClose={() => {
          setDrawerVisible(false)
          setIsEditing(false)
          setCurrentStudent(null)
          form.resetFields()
        }}
        visible={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <AddStudentForm
          onSubmit={isEditing ? handleEditStudent : handleAddStudent}
          form={form}
          isEditing={isEditing}
        />
      </Drawer>
    </div>
  )
}

export default Students
