import React, { useEffect, useState } from 'react'
import {
  Card,
  Row,
  Col,
  Select,
  Spin,
  Alert,
  Button,
  Drawer,
  Form,
  Popconfirm,
  Input, // Import Input component for search
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
  fetchCourses,
  createCourse,
  updateCourse,
  deleteCourse,
  fetchStudentCourses,
  searchCourse, // Ensure this action is correctly implemented
} from './courses.api'
import AddCourseForm from './Form/AddCourseForm'
import { fetchCampuses } from '../Campuses/campuses.api'
import dayjs from 'dayjs'
import tinycolor from 'tinycolor2'

const { Meta } = Card
const { Option } = Select
const { Search } = Input // Destructure Search from Input

const CourseCard = () => {
  const [coursesToShow, setCoursesToShow] = useState(9)
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [currentCourse, setCurrentCourse] = useState(null)
  const [searchQuery, setSearchQuery] = useState('') // State to store search query
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { studentId } = useParams()

  // Destructure necessary state from Redux store
  const { courses, loading, error, searchedCourses } = useSelector(
    (state) => state.courses,
  )
  const { campuses } = useSelector((state) => state.campuses)

  // Function to generate a consistent gradient based on the course color
  const generateColorGradient = (color) => {
    if (!color) return 'linear-gradient(135deg, #e0e0e0, #f5f5f5)' // Fallback color gradient

    // Generate a lighter or darker variant based on the original color
    const baseColor = tinycolor(color)
    const variantColor = baseColor.isLight()
      ? baseColor.darken(20).toString()
      : baseColor.lighten(20).toString()

    // Fixed gradient angle for consistency
    return `linear-gradient(135deg, ${color}, ${variantColor})`
  }

  // Fetch courses and campuses on component mount if not viewing as a student
  useEffect(() => {
    if (!studentId) {
      dispatch(fetchCourses())
      dispatch(fetchCampuses())
    }
  }, [dispatch, studentId])

  // Fetch student-specific courses if viewing as a student
  useEffect(() => {
    if (studentId) {
      dispatch(fetchStudentCourses(studentId))
    }
  }, [dispatch, studentId])

  // Handle search input change
  const handleSearch = (value) => {
    setSearchQuery(value) // Update search query state
    if (value) {
      dispatch(searchCourse(value)) // Dispatch searchCourse API call
    } else {
      // Optionally, you can refetch all courses or reset the search
      dispatch(fetchCourses())
    }
  }

  const handleSelectChange = (value) => {
    setCoursesToShow(value)
  }

  const handleCardClick = (courseId) => {
    navigate(`/courses/${courseId}/modules`)
  }

  const handleAddCourse = (values) => {
    dispatch(createCourse(values)).then(() => {
      setIsDrawerVisible(false)
      form.resetFields()
      dispatch(fetchCourses())
    })
  }

  const handleEditCourse = (values) => {
    if (currentCourse) {
      dispatch(updateCourse({ id: currentCourse.id, ...values })).then(() => {
        setIsDrawerVisible(false)
        setIsEditing(false)
        setCurrentCourse(null)
        form.resetFields()
        dispatch(fetchCourses())
      })
    }
  }

  const handleEditClick = (e, course) => {
    e.stopPropagation()

    form.setFieldsValue({
      name: course.name,
      description: course.description,
      campus_id: course.campus.id,
      learning_outcome: course.learning_outcome,
      module_outline: course.module_outline,
      start_date: course.start_date ? dayjs(course.start_date) : null,
      end_date: course.end_date ? dayjs(course.end_date) : null,
      color: course.color || '#005d41', // Set default color if not present
    })

    setCurrentCourse(course)
    setIsEditing(true)
    setIsDrawerVisible(true)
  }

  const handleDeleteCourse = (courseId, e) => {
    e.stopPropagation()
    dispatch(deleteCourse(courseId)).then(() => {
      dispatch(fetchCourses())
    })
  }

  // Determine which courses to display based on search query
  const displayedCourses = searchQuery ? searchedCourses : courses

  // Render loading state
  if (loading) {
    return <Spin tip="Loading courses..." />
  }

  // Render error state
  if (error) {
    return <Alert message="Error" description={error} type="error" showIcon />
  }

  return (
    <>
      {/* Header Section with Search and Add Course Button */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '16px',
        }}
      >
        <h2 style={{ margin: 0 }}>Courses</h2>
        <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
          {/* Add Search Input */}
          <Search
            placeholder="Search Courses"
            allowClear
            enterButton
            style={{ width: 300 }}
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
          />
          {/* Select Dropdown to Control Number of Courses Displayed */}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>Show&nbsp;</span>
            <Select
              defaultValue={coursesToShow.toString()}
              onChange={handleSelectChange}
              style={{ width: 80 }}
            >
              <Option value="3">3</Option>
              <Option value="6">6</Option>
              <Option value="9">9</Option>
            </Select>
            <span>&nbsp;courses</span>
          </div>
          {/* Add Course Button */}
          {!studentId && (
            <Button
              style={{ backgroundColor: '#005d41', color: 'white' }}
              type="primary"
              onClick={() => {
                setIsEditing(false)
                setCurrentCourse(null)
                form.resetFields()
                setIsDrawerVisible(true)
              }}
            >
              Add Course
            </Button>
          )}
        </div>
      </div>

      {/* Conditional Rendering Based on Displayed Courses */}
      {displayedCourses.length === 0 ? (
        <Alert
          message="No Courses Available"
          description={
            searchQuery
              ? 'No courses match your search.'
              : !studentId
                ? 'There are currently no courses to display. Please add a new course.'
                : 'You are currently not associated with any course.'
          }
          type="info"
          showIcon
          style={{ marginBottom: '16px' }}
        />
      ) : (
        <Row gutter={[16, 16]}>
          {displayedCourses.slice(0, coursesToShow).map((course) => (
            <Col key={course.id} span={6}>
              <Card
                hoverable
                style={{
                  width: '100%',
                  borderRadius: 20,
                  marginBottom: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '400px',
                }}
                cover={
                  <div
                    style={{
                      background: generateColorGradient(course.color), // Generate consistent gradient from course color
                      height: '160px',
                      borderTopRightRadius: 20,
                      borderTopLeftRadius: 20,
                      backgroundSize: 'cover',
                    }}
                  ></div>
                }
                onClick={() => handleCardClick(course.id)}
              >
                <Meta
                  title={<span style={{ fontWeight: 600 }}>{course.name}</span>}
                  description={
                    <>
                      <p>
                        <b>Campus:</b> {course.campus.name}
                      </p>
                      <p
                        style={{
                          display: '-webkit-box',
                          WebkitLineClamp: 3, // Limit to 3 lines
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis', // Add ellipsis
                          height: '60px',
                        }}
                      >
                        <b>Description:</b> {course.description}
                      </p>
                    </>
                  }
                />
                {!studentId && (
                  <>
                    <Button
                      type="primary"
                      style={{
                        marginTop: '10px',
                        marginRight: '10px',
                        backgroundColor: '#005d41',
                        color: 'white',
                      }}
                      onClick={(e) => handleEditClick(e, course)}
                    >
                      Edit
                    </Button>
                    <Popconfirm
                      title="Are you sure to delete this course?"
                      onConfirm={(e) => handleDeleteCourse(course.id, e)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        type="primary"
                        danger
                        style={{
                          backgroundColor: '#cb0000',
                          color: 'white',
                          marginTop: '10px',
                        }}
                        onClick={(e) => e.stopPropagation()} // Prevent card click
                      >
                        Delete
                      </Button>
                    </Popconfirm>
                  </>
                )}
              </Card>
            </Col>
          ))}
        </Row>
      )}

      {/* Drawer for Adding and Editing Courses */}
      <Drawer
        title={isEditing ? 'Edit Course' : 'Add New Course'}
        visible={isDrawerVisible}
        onClose={() => {
          setIsDrawerVisible(false)
          setIsEditing(false)
          setCurrentCourse(null)
          form.resetFields()
        }}
        footer={null}
        width={600}
      >
        <AddCourseForm
          form={form}
          setIsDrawerVisible={setIsDrawerVisible}
          campuses={campuses}
          handleAddCourse={handleAddCourse}
          handleEditCourse={handleEditCourse}
          isEditing={isEditing}
        />
      </Drawer>
    </>
  )
}

export default CourseCard
