// IntakeDetails.jsx

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  Spin,
  Alert,
  List,
  Collapse,
  Button,
  Drawer,
  Divider,
  Form,
  Popconfirm,
  message,
} from 'antd'
import {
  fetchIntakeDetails,
  deleteStaffFromIntake,
  deleteStudentFromIntake,
} from './intakes.api'
import AddStudentForm from './Form/AddStudentForm'
import AddStaffForm from './Form/AddStaffForm'
import AddIntakePage from './Form/AddIntakePage'
import {
  UserAddOutlined,
  EditOutlined,
  TeamOutlined,
  DeleteOutlined,
} from '@ant-design/icons' // Import Delete icon

// import { deleteStaff, deleteStudent } from './intakes.actions' // Import delete actions

const { Panel } = Collapse

const IntakeDetails = () => {
  const { intakeId } = useParams()
  const dispatch = useDispatch()
  const { intakeDetails, loading, error } = useSelector(
    (state) => state.intakes,
  )
  const studentId = localStorage.getItem('studentId')
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [formType, setFormType] = useState(null) // 'student', 'staff', or 'intake'
  const [editMode, setEditMode] = useState(false) // Track if we are in edit mode
  const [form] = Form.useForm() // Ant Design form instance

  // Function to refresh intake details after adding a student or staff
  const refreshIntakeDetails = () => {
    if (intakeId) {
      dispatch(fetchIntakeDetails(intakeId))
    }
  }

  useEffect(() => {
    refreshIntakeDetails() // Fetch intake details on component mount
  }, [dispatch, intakeId])

  const showDrawer = (type) => {
    setFormType(type)
    setDrawerVisible(true)
  }

  const onClose = () => {
    setDrawerVisible(false)
    setEditMode(false) // Reset edit mode when closing the drawer
  }

  const handleEditIntake = () => {
    setEditMode(true) // Enable edit mode
    setFormType('intake') // Set form type to intake to show the edit intake form
    setDrawerVisible(true)
  }

  // Handler to delete a staff member
  const handleDeleteStaff = async (staffId, staffName) => {
    try {
      await dispatch(deleteStaffFromIntake(intakeId, staffId))
      message.success(`Successfully deleted staff member: ${staffName}`)
    } catch (err) {
      message.error(
        `Failed to delete staff member: ${err.response?.data?.message || err.message}`,
      )
    }
  }

  // Handler to delete a student
  const handleDeleteStudent = async (studentId, studentName) => {
    try {
      await dispatch(deleteStudentFromIntake(intakeId, studentId))
      message.success(`Successfully deleted student: ${studentName}`)
    } catch (err) {
      message.error(
        `Failed to delete student: ${err.response?.data?.message || err.message}`,
      )
    }
  }

  if (loading) {
    return <Spin tip="Loading intake details..." />
  }

  if (error) {
    return <Alert message="Error" description={error} type="error" showIcon />
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h2>Intake Details</h2>
        {!studentId && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* Add Edit Intake Button */}
            <Button
              type="primary"
              onClick={handleEditIntake}
              icon={<EditOutlined />} // Add icon
              style={{
                marginRight: '10px',
                backgroundColor: '#005d41',
                color: 'white',
              }}
            >
              Edit Intake
            </Button>
            <Divider type="vertical" style={{ height: '100%' }} />
            {/* Add Student and Staff Buttons */}
            <Button
              style={{
                marginLeft: '10px',
                backgroundColor: '#52c41a',
                color: 'white',
              }}
              type="primary"
              icon={<UserAddOutlined />} // Add icon for student
              onClick={() => showDrawer('student')}
            >
              Add Student
            </Button>
            <Button
              type="primary"
              onClick={() => showDrawer('staff')}
              icon={<TeamOutlined />} // Add icon for staff
              style={{
                marginLeft: '10px',
                backgroundColor: '#C3953A',
                color: 'white',
              }}
            >
              Add Staff
            </Button>
          </div>
        )}
      </div>

      {/* Display Intake Details in a List */}
      {intakeDetails && (
        <List
          bordered
          style={{ marginBottom: 24 }} // Add some spacing between the list and collapse
        >
          <List.Item>
            <strong>Name:</strong> {intakeDetails.name}
          </List.Item>
          <List.Item>
            <strong>Description:</strong> {intakeDetails.description}
          </List.Item>
          <List.Item>
            <strong>Campus:</strong> {intakeDetails.campus?.name}
          </List.Item>
          <List.Item>
            <strong>Status:</strong> {intakeDetails.status}
          </List.Item>
          <List.Item>
            <strong>Capacity:</strong> {intakeDetails.capacity}
          </List.Item>
        </List>
      )}

      {/* Collapse Panels for Courses, Staffs, and Students */}
      {intakeDetails && (
        <Collapse defaultActiveKey={['1', '2']}>
          <Panel header="Courses" key="1">
            <List
              itemLayout="horizontal"
              dataSource={intakeDetails.courses}
              renderItem={(course) => (
                <List.Item>
                  <List.Item.Meta
                    title={course.name}
                    description={course.description}
                  />
                </List.Item>
              )}
            />
          </Panel>
          <Panel header="Staffs" key="2">
            <Collapse accordion>
              {intakeDetails.staffs.map((staff) => (
                <Panel
                  header={`${staff.first_name} ${staff.last_name}`}
                  key={`staff-${staff.id}`} // Ensure each panel has a unique key
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div>
                      <p>
                        <strong>Email:</strong> {staff.email}
                      </p>
                      {/* Nested Collapse for Courses */}
                      <Collapse>
                        <Panel header="Courses" key="staff-courses">
                          <List
                            size="small"
                            dataSource={staff.intakes[0]?.courses || []}
                            renderItem={(course) => (
                              <List.Item>{course.name}</List.Item>
                            )}
                          />
                        </Panel>
                      </Collapse>
                    </div>
                    {/* Delete Button */}
                    <Popconfirm
                      title={`Are you sure you want to delete ${staff.first_name} ${staff.last_name} from this intake?`}
                      onConfirm={() =>
                        handleDeleteStaff(
                          staff.id,
                          `${staff.first_name} ${staff.last_name}`,
                        )
                      }
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        type="danger"
                        icon={<DeleteOutlined style={{ color: 'red' }} />}
                      />
                    </Popconfirm>
                  </div>
                </Panel>
              ))}
            </Collapse>
          </Panel>
          {!studentId && (
            <Panel header="Students" key="3">
              <Collapse accordion>
                {intakeDetails.students.map((student) => (
                  <Panel
                    header={`${student.first_name} ${student.last_name}`}
                    key={`student-${student.id}`} // Ensure each panel has a unique key
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        <p>
                          <strong>Email:</strong> {student.email}
                        </p>
                        {/* Nested Collapse for Courses */}
                        <Collapse>
                          <Panel header="Courses" key="student-courses">
                            <List
                              size="small"
                              dataSource={student.intakes[0]?.courses || []}
                              renderItem={(course) => (
                                <List.Item>{course.name}</List.Item>
                              )}
                            />
                          </Panel>
                        </Collapse>
                      </div>
                      {/* Delete Button */}
                      <Popconfirm
                        title={`Are you sure you want to delete ${student.first_name} ${student.last_name} from this intake?`}
                        onConfirm={() =>
                          handleDeleteStudent(
                            student.id,
                            `${student.first_name} ${student.last_name}`,
                          )
                        }
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button
                          type="danger"
                          icon={<DeleteOutlined style={{ color: 'red' }} />}
                        />
                      </Popconfirm>
                    </div>
                  </Panel>
                ))}
              </Collapse>
            </Panel>
          )}
        </Collapse>
      )}

      {/* Drawer for Adding and Editing Intakes */}
      <Drawer
        title={
          formType === 'intake'
            ? 'Edit Intake'
            : formType === 'student'
              ? 'Add Student'
              : 'Add Staff'
        }
        width={500}
        onClose={onClose}
        visible={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        {/* Show appropriate form based on formType */}
        {formType === 'intake' ? (
          <AddIntakePage
            setDrawerVisible={() => setDrawerVisible(false)}
            intakeData={intakeDetails}
            editMode={editMode}
            form={form}
            refreshIntakeDetails={refreshIntakeDetails}
          />
        ) : formType === 'student' ? (
          <AddStudentForm
            intakeId={intakeId}
            intakeDetails={intakeDetails}
            onClose={onClose}
            refreshIntakeDetails={refreshIntakeDetails} // Pass refresh function to AddStudentForm
          />
        ) : (
          <AddStaffForm
            intakeId={intakeId}
            intakeDetails={intakeDetails}
            onClose={onClose}
            refreshIntakeDetails={refreshIntakeDetails} // Pass refresh function to AddStaffForm
          />
        )}
      </Drawer>
    </div>
  )
}

export default IntakeDetails
