import React, { useState } from 'react'
import { Form, Input, Button, Select, Upload, DatePicker } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import moment from 'moment'

const { Option } = Select

// eslint-disable-next-line react/prop-types
const AddFileForm = ({ form, handleAddFile }) => {
  const [fileType, setFileType] = useState('assignment') // Default to 'assignment'

  const handleFileTypeChange = (value) => {
    setFileType(value)
  }

  return (
    <Form layout="vertical" form={form} onFinish={handleAddFile}>
      <Form.Item
        name="fileName"
        label="File Name"
        rules={[{ required: true, message: 'Please enter the file name' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="fileType"
        label="File Type"
        rules={[{ required: true, message: 'Please select the file type' }]}
      >
        <Select onChange={handleFileTypeChange} value={fileType}>
          <Option value="assignment">Assignment</Option>
          <Option value="presentation">Presentation</Option>
        </Select>
      </Form.Item>

      {fileType === 'assignment' && (
        <>
          <Form.Item
            name="startTime"
            label="Start Time (UTC)"
            rules={[
              { required: true, message: 'Please select the start time' },
            ]}
          >
            <DatePicker
              showTime
              format="YYYY-MM-DD HH:mm:ss"
              placeholder="Select start time"
              disabledDate={(current) =>
                current && current < moment().startOf('day')
              }
            />
          </Form.Item>

          <Form.Item
            name="endTime"
            label="End Time (UTC)"
            rules={[{ required: true, message: 'Please select the end time' }]}
          >
            <DatePicker
              showTime
              format="YYYY-MM-DD HH:mm:ss"
              placeholder="Select end time"
              disabledDate={(current) =>
                current && current < moment().startOf('day')
              }
            />
          </Form.Item>
        </>
      )}

      <Form.Item
        name="file"
        label="Upload File"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'Please upload the file' }]}
      >
        <Upload name="file" beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Click to upload</Button>
        </Upload>
      </Form.Item>

      <Form.Item>
        <Button
          style={{ backgroundColor: '#005d41', color: 'white' }}
          type="primary"
          htmlType="submit"
        >
          Add File
        </Button>
      </Form.Item>
    </Form>
  )
}

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e
  }
  return e && e.fileList
}

export default AddFileForm
