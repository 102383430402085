// actions.js
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAILURE = 'REGISTER_FAILURE'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'

export const registerSuccess = (user) => ({
  type: REGISTER_SUCCESS,
  payload: user,
})

export const registerFailure = (error) => ({
  type: REGISTER_FAILURE,
  payload: error,
})

export const loginSuccess = (user) => ({
  type: LOGIN_SUCCESS,
  payload: user,
})

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
})

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
})
