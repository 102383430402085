/* eslint-disable */
import React from 'react'
import { List, Button, Popconfirm } from 'antd'
import {
  DownloadOutlined,
  FilePdfOutlined,
  FileImageOutlined,
  FileOutlined,
  DeleteOutlined,
} from '@ant-design/icons'

const PresentationPanel = ({ presentations, onDelete, studentId }) => {
  const downloadFile = (url) => {
    const link = document.createElement('a')
    link.href = url
    link.download = url.split('/').pop()
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const getIcon = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase()
    switch (extension) {
      case 'pdf':
        return <FilePdfOutlined />
      case 'png':
      case 'jpg':
      case 'jpeg':
        return <FileImageOutlined />
      default:
        return <FileOutlined />
    }
  }

  return (
    <List
      itemLayout="horizontal"
      dataSource={presentations}
      renderItem={(item) => (
        <List.Item
          key={item.id}
          actions={[
            <>
              <Button
                key="downloadButton"
                type="primary"
                style={{
                  backgroundColor: '#005d41',
                  color: 'white',
                  marginRight: '10px',
                }}
                icon={<DownloadOutlined />}
                onClick={() => downloadFile(item.file_path)}
              >
                Download Presentation
              </Button>
              {!studentId && (
                <Popconfirm
                  key="deleteButton"
                  title="Are you sure you want to delete this presentation?"
                  onConfirm={() => onDelete(item.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    type="danger"
                    style={{ backgroundColor: '#cb0000', color: 'white' }}
                    icon={<DeleteOutlined />}
                  >
                    Delete
                  </Button>
                </Popconfirm>
              )}
            </>,
          ]}
        >
          <List.Item.Meta
            avatar={getIcon(item.file_name)}
            title={item.file_name}
            description={item.file_path}
          />
        </List.Item>
      )}
    />
  )
}

export default PresentationPanel
