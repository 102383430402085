import request from '../../core/request'
import { openNotification } from '../../utils/Notification'
import {
  fetchModulesRequest,
  fetchModulesSuccess,
  fetchModulesFailure,
  createModuleRequest,
  createModuleSuccess,
  createModuleFailure,
  updateModuleRequest,
  updateModuleSuccess,
  updateModuleFailure,
  deleteModuleRequest,
  deleteModuleSuccess,
  deleteModuleFailure,
  fetchModuleDetailsRequest,
  fetchModuleDetailsSuccess,
  fetchModuleDetailsFailure,
  uploadFilesRequest,
  uploadFilesSuccess,
  uploadFilesFailure,
  uploadAssignmentRequest,
  uploadAssignmentSuccess,
  uploadAssignmentFailure,
  provideAssignmentFeedbackRequest,
  provideAssignmentFeedbackSuccess,
  provideAssignmentFeedbackFailure,
  toggleUploadRequest,
  toggleUploadSuccess,
  toggleUploadFailure,
  deleteFileRequest,
  deleteFileSuccess,
  deleteFileFailure,
  fetchSubmissionsRequest,
  fetchSubmissionsSuccess,
  fetchSubmissionsFailure,
} from './modules.actions'
import { message } from 'antd'

// Function to handle fetching modules
export const fetchModules = (courseId) => {
  return (dispatch) => {
    dispatch(fetchModulesRequest())
    const url = courseId ? `/modules/?course_id=${courseId}` : '/modules/'
    return request({
      method: 'GET',
      url,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(fetchModulesSuccess(response.data))
        } else if (response?.status === 204) {
          dispatch(fetchModulesSuccess([]))
        } else {
          dispatch(fetchModulesFailure('Fetching modules failed'))
          openNotification({
            type: 'error',
            title: 'Fetching Modules Failed',
            description: 'Fetching modules failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          fetchModulesFailure('An error occurred while fetching modules'),
        )
        openNotification({
          type: 'error',
          title: 'Fetching Modules Error',
          description: `An error occurred while fetching modules: ${error.message}`,
        })
      })
  }
}

// Function to handle creating a module
export const createModule = (moduleData) => {
  return (dispatch) => {
    dispatch(createModuleRequest(moduleData))
    return request({
      method: 'POST',
      url: '/modules/',
      data: moduleData,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(createModuleSuccess(response.data))
        } else {
          dispatch(createModuleFailure('Creating module failed'))
          openNotification({
            type: 'error',
            title: 'Creating Module Failed',
            description: 'Creating module failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          createModuleFailure('An error occurred while creating the module'),
        )
        openNotification({
          type: 'error',
          title: 'Creating Module Error',
          description: `An error occurred while creating the module: ${error.message}`,
        })
      })
  }
}

// Function to handle updating a module
export const updateModule = (moduleData) => {
  return (dispatch) => {
    dispatch(updateModuleRequest(moduleData))
    return request({
      method: 'PUT',
      url: `/modules/${moduleData.id}`,
      data: moduleData,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(updateModuleSuccess(response.data))
        } else {
          dispatch(updateModuleFailure('Updating module failed'))
          openNotification({
            type: 'error',
            title: 'Updating Module Failed',
            description: 'Updating module failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          updateModuleFailure('An error occurred while updating the module'),
        )
        openNotification({
          type: 'error',
          title: 'Updating Module Error',
          description: `An error occurred while updating the module: ${error.message}`,
        })
      })
  }
}

// Function to handle deleting a module
export const deleteModule = (moduleId) => {
  return (dispatch) => {
    dispatch(deleteModuleRequest(moduleId))
    return request({
      method: 'DELETE',
      url: `/modules/${moduleId}?deletion_type=hard`,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(deleteModuleSuccess(moduleId))
        } else {
          dispatch(deleteModuleFailure('Deleting module failed'))
          openNotification({
            type: 'error',
            title: 'Deleting Module Failed',
            description: 'Deleting module failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          deleteModuleFailure('An error occurred while deleting the module'),
        )
        openNotification({
          type: 'error',
          title: 'Deleting Module Error',
          description: `An error occurred while deleting the module: ${error.message}`,
        })
      })
  }
}

// Function to handle fetching module details
export const fetchModuleDetails = (moduleId, studentId) => {
  return (dispatch) => {
    dispatch(fetchModuleDetailsRequest())
    const url = studentId
      ? `/modules/${moduleId}?studentId=${studentId}`
      : `/modules/${moduleId}`
    return request({
      method: 'GET',
      url,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(fetchModuleDetailsSuccess(response.data))
        } else {
          dispatch(fetchModuleDetailsFailure('Fetching module details failed'))
          openNotification({
            type: 'error',
            title: 'Fetching Module Details Failed',
            description: 'Fetching module details failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          fetchModuleDetailsFailure(
            'An error occurred while fetching module details',
          ),
        )
        openNotification({
          type: 'error',
          title: 'Fetching Module Details Error',
          description: `An error occurred while fetching module details: ${error.message}`,
        })
      })
  }
}

// teacher upload files here
export const uploadFiles = (data) => {
  const formData = new FormData()
  formData.append('file', data.file)

  return (dispatch) => {
    dispatch(uploadFilesRequest())

    const token = localStorage.getItem('token')

    // Build the query parameters from the additional data
    const queryParams = new URLSearchParams()

    if (data.file_data.module_id) {
      queryParams.append('module_id', data.file_data.module_id)
    }
    if (data.file_data.file_type) {
      queryParams.append('file_type', data.file_data.file_type)
    }
    if (data.file_data.admin_id) {
      queryParams.append('admin_id', data.file_data.admin_id)
    }

    return request({
      method: 'POST',
      url: `/files/?${queryParams.toString()}`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response?.data) {
          dispatch(uploadFilesSuccess(response.data))
        } else {
          dispatch(uploadFilesFailure('Uploading files failed'))
          openNotification({
            type: 'error',
            title: 'Uploading Files Failed',
            description: 'Uploading files failed',
          })
        }
      })
      .catch((error) => {
        dispatch(uploadFilesFailure('An error occurred while uploading files'))
        openNotification({
          type: 'error',
          title: 'Uploading Files Error',
          description: `An error occurred while uploading files: ${error.message}`,
        })
      })
  }
}

export const uploadStudentSubmission = (data) => {
  console.log('data', data)
  const formData = new FormData()
  formData.append('submission_file', data.file)
  formData.append('student_id', data.student_id)
  formData.append('file_id', data.file_id)

  return (dispatch) => {
    dispatch(uploadFilesRequest())

    const token = localStorage.getItem('token')
    return request({
      method: 'POST',
      url: `/student-submissions/?file_id=${data.file_id}&student_id=${data.student_id}`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response?.data) {
          dispatch(uploadAssignmentSuccess(response.data))
          message.success('Submission uploaded successfully')
        } else {
          dispatch(uploadAssignmentFailure('Uploading submission failed'))
          openNotification({
            type: 'error',
            title: 'Uploading Files Failed',
            description: 'Uploading files failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          uploadAssignmentFailure('An error occurred while uploading files'),
        )
        openNotification({
          type: 'error',
          title: 'Uploading Submission Error',
          description: `An error occurred while uploading submission: ${error.message}`,
        })
      })
  }
}

// Function to handle uploading assignments
export const uploadAssignment = (fileData) => {
  return (dispatch) => {
    const token = localStorage.getItem('token')
    dispatch(uploadAssignmentRequest())
    return request({
      method: 'POST',
      url: '/modules/student-submissions/',
      data: fileData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response?.data) {
          dispatch(uploadAssignmentSuccess(response.data))
        } else {
          dispatch(uploadAssignmentFailure('Uploading assignment failed'))
          openNotification({
            type: 'error',
            title: 'Uploading Assignment Failed',
            description: 'Uploading assignment failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          uploadAssignmentFailure(
            'An error occurred while uploading assignment',
          ),
        )
        openNotification({
          type: 'error',
          title: 'Uploading Assignment Error',
          description: `An error occurred while uploading assignment: ${error.message}`,
        })
      })
  }
}

export const provideAssignmentFeedback = (assignmentId, feedback) => {
  return (dispatch) => {
    dispatch(provideAssignmentFeedbackRequest())
    const token = localStorage.getItem('token')
    return request({
      method: 'POST',
      url: `/modules/assignments/${assignmentId}/feedback/`,
      data: { feedback },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response?.data) {
          dispatch(provideAssignmentFeedbackSuccess(response.data))
        } else {
          dispatch(
            provideAssignmentFeedbackFailure('Providing feedback failed'),
          )
          openNotification({
            type: 'error',
            title: 'Providing Feedback Failed',
            description: 'Providing feedback failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          provideAssignmentFeedbackFailure(
            'An error occurred while providing feedback',
          ),
        )
        openNotification({
          type: 'error',
          title: 'Providing Feedback Error',
          description: `An error occurred while providing feedback: ${error.message}`,
        })
      })
  }
}

export const toggleAllowUpload = (submissionId, allow_revision, feedback) => {
  return (dispatch) => {
    dispatch(toggleUploadRequest())
    const token = localStorage.getItem('token')
    return request({
      method: 'PUT',
      url: `/student-submissions/${submissionId}/feedback`,
      data: { allow_revision, feedback },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response?.data) {
          dispatch(toggleUploadSuccess(response.data))
        } else {
          dispatch(toggleUploadFailure('Providing feedback failed'))
          openNotification({
            type: 'error',
            title: 'Providing Feedback Failed',
            description: 'Providing feedback failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          toggleUploadFailure('An error occurred while providing feedback'),
        )
        openNotification({
          type: 'error',
          title: 'Providing Feedback Error',
          description: `An error occurred while providing feedback: ${error.message}`,
        })
      })
  }
}

export const deleteFile = (fileId) => {
  return (dispatch) => {
    dispatch(deleteFileRequest(fileId))
    return request({
      method: 'DELETE',
      url: `/files/${fileId}?deletion_type=hard`,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(deleteFileSuccess(fileId))
        } else {
          dispatch(deleteFileFailure('Deleting file failed'))
          openNotification({
            type: 'error',
            title: 'Deleting Module Failed',
            description: 'Deleting module failed',
          })
        }
      })
      .catch((error) => {
        dispatch(deleteFileFailure('An error occurred while deleting the file'))
        openNotification({
          type: 'error',
          title: 'Deleting File Error',
          description: `An error occurred while deleting the file: ${error.message}`,
        })
      })
  }
}

// Function to fetch submissions for a specific assignment
export const fetchSubmissions = (assignmentId, studentId) => {
  return (dispatch) => {
    dispatch(fetchSubmissionsRequest())
    const token = localStorage.getItem('token')

    return request({
      method: 'GET',
      url: studentId
        ? `/student-submissions/?file_id=${assignmentId}&student_id=${studentId}`
        : `/student-submissions/?file_id=${assignmentId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log('response', response)
        if (response?.data) {
          dispatch(fetchSubmissionsSuccess(response.data))
        } else if (response?.status === 204) {
          dispatch(fetchSubmissionsSuccess([]))
        } else {
          dispatch(fetchSubmissionsFailure('Fetching submissions failed'))
          openNotification({
            type: 'error',
            title: 'Fetching Submissions Failed',
            description: 'Fetching submissions failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          fetchSubmissionsFailure(
            'An error occurred while fetching submissions',
          ),
        )
        openNotification({
          type: 'error',
          title: 'Fetching Submissions Error',
          description: `An error occurred while fetching submissions: ${error.message}`,
        })
      })
  }
}
