import { connect } from 'react-redux'
import Intakes from './Intakes'
import {
  fetchIntakes,
  createIntake,
  updateIntake,
  deleteIntake,
} from './intakes.api'

const mapStateToProps = (state) => ({
  intakes: state.intakes.intakes,
  loading: state.intakes.loading,
  error: state.intakes.error,
  searchedStudents: state.students.searchedStudents,
})

const mapDispatchToProps = (dispatch) => ({
  fetchIntakes: () => dispatch(fetchIntakes()),
  createIntake: (studentData) => dispatch(createIntake(studentData)),
  updateIntake: (studentData, data) =>
    dispatch(updateIntake(studentData, data)),
  deleteIntake: (studentId) => dispatch(deleteIntake(studentId)),
})

export const IntakesContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Intakes)
