// useAxiosInterceptor.js
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { logout } from '../components/Auth/auth.api'

const useAxiosInterceptor = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          dispatch(logout())
          localStorage.removeItem('isAuthenticated')
          localStorage.removeItem('role')
          localStorage.removeItem('studentId')
          localStorage.removeItem('adminId')
          navigate('/login')
        }
        return error
      },
    )
  }, [dispatch, navigate])
}

export default useAxiosInterceptor
