import { connect } from 'react-redux'
import Dashboard from './Dashboard.view'
import { increment, decrement } from './dashboard.actions'

const mapStateToProps = (state) => ({
  counter: state.dashboard.counter,
})

const mapDispatchToProps = (dispatch) => ({
  increment: () => {
    dispatch(increment())
  },
  decrement: () => {
    dispatch(decrement())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
