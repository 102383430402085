import { connect } from 'react-redux'
import Campuses from './Campuses'
import {
  fetchCampuses,
  createCampus,
  updateCampus,
  deleteCampus,
} from './campuses.api'

const mapStateToProps = (state) => ({
  campuses: state.campuses.campuses,
  loading: state.campuses.loading,
  error: state.campuses.error,
})

const mapDispatchToProps = (dispatch) => ({
  fetchCampuses: () => dispatch(fetchCampuses()),
  createCampus: (campusData) => dispatch(createCampus(campusData)),
  updateCampus: (campusData) => dispatch(updateCampus(campusData)),
  deleteCampus: (campusId) => dispatch(deleteCampus(campusId)),
})

export const CampusesContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Campuses)
