import { combineReducers } from 'redux'

// Import your reducers here
import dashboard from '../components/Dashboard/dashboard.reducer'
import auth from '../components/Auth/auth.reducer'
import courses from '../components/Courses/courses.reducer'
import modules from '../components/Modules/modules.reducer'
import students from '../components/Students/students.reducer'
import staffs from '../components/Staffs/staffs.reducer'
import intakes from '../components/Intakes/intakes.reducer'
import campuses from '../components/Campuses/campuses.reducer'
import admins from '../components/Admins/admin.reducer'

const rootReducer = combineReducers({
  // Add your reducers here
  dashboard,
  auth,
  courses,
  modules,
  students,
  staffs,
  intakes,
  campuses,
  admins,
})

export default rootReducer
