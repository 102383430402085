import React from 'react'
import { Navigate } from 'react-router-dom'

// eslint-disable-next-line react/prop-types
const ProtectedRoute = ({ children, allowedRoles }) => {
  const role = localStorage.getItem('role')
  // eslint-disable-next-line react/prop-types
  if (!allowedRoles.includes(role)) {
    return <Navigate to="/unauthorized" replace />
  }
  return children
}

export default ProtectedRoute
