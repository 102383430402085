import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Button } from 'antd'
import ReactQuill from 'react-quill' // Import React Quill
import 'react-quill/dist/quill.snow.css' // Import Quill styles

const AddModuleForm = ({
  form,
  handleAddModule,
  handleEditModule,
  isEditing,
}) => {
  const [description, setDescription] = useState('')

  const handleDescriptionChange = (value) => {
    setDescription(value) // Updates state when description changes
  }

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={isEditing ? handleEditModule : handleAddModule}
    >
      <Form.Item
        name="name"
        label="Module Name"
        rules={[{ required: true, message: 'Please enter the module name' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="description"
        label="Description"
        rules={[{ required: true, message: 'Please enter the description' }]}
      >
        {/* Replace Input with React Quill for rich text editing */}
        <ReactQuill value={description} onChange={handleDescriptionChange} />
      </Form.Item>

      <Form.Item>
        <Button
          style={{ backgroundColor: '#005d41', color: 'white' }}
          type="primary"
          htmlType="submit"
        >
          {isEditing ? 'Update Module' : 'Add Module'}
        </Button>
      </Form.Item>
    </Form>
  )
}

AddModuleForm.propTypes = {
  form: PropTypes.object.isRequired,
  handleAddModule: PropTypes.func.isRequired,
  handleEditModule: PropTypes.func,
  isEditing: PropTypes.bool.isRequired,
}

AddModuleForm.defaultProps = {
  handleEditModule: () => {},
}

export default AddModuleForm
