/* eslint-disable */
import React from 'react'
import { Modal, Input, Button } from 'antd'

const FeedbackModal = ({
  feedbackModalVisible,
  handleCancel,
  feedbackValue,
  setFeedbackValue,
  handleFeedbackSubmit,
  studentId,
}) => {
  return (
    <Modal
      title="Feedback"
      visible={feedbackModalVisible}
      onCancel={handleCancel}
      footer={[
        !studentId && (
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>
        ),
        studentId ? null : (
          <Button
            style={{ backgroundColor: '#005d41', color: 'white' }}
            key="submit"
            type="primary"
            onClick={handleFeedbackSubmit}
          >
            Submit
          </Button>
        ),
      ]}
    >
      <Input.TextArea
        value={feedbackValue}
        onChange={(e) => setFeedbackValue(e.target.value)}
        placeholder={!studentId && 'Provide feedback'}
        rows={4}
        readOnly={!!studentId}
      />
    </Modal>
  )
}

export default FeedbackModal
