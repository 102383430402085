import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './reducers'
import loggerMiddleware from 'redux-logger'

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(loggerMiddleware),
  // Optionally, you can provide middleware, enhancers, etc.
})

export default store
