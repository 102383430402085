import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  Table,
  Button,
  Upload,
  Spin,
  Alert,
  Empty,
  message,
  Form,
  Typography,
} from 'antd'
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons'
import { fetchSubmissions, uploadStudentSubmission } from './modules.api'

const { Text } = Typography

const StudentSubmissions = () => {
  const { assignmentId, studentId } = useParams()
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const {
    fetchedSubmissions: submissions,
    loading,
    error,
  } = useSelector((state) => state.modules)
  const [fileList, setFileList] = useState([])
  const [expandedFeedback, setExpandedFeedback] = useState({}) // State to manage which feedback is expanded

  useEffect(() => {
    dispatch(fetchSubmissions(assignmentId, studentId))
  }, [dispatch, assignmentId, studentId])

  const downloadFile = (url) => {
    const link = document.createElement('a')
    link.href = url
    link.download = url.split('/').pop()
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const handleFileChange = ({ file }) => {
    setFileList([file]) // Allow only one file
  }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const handleSubmitAssignment = (values) => {
    if (fileList.length === 0) {
      message.error('Please select a file to upload')
      return
    }

    const data = {
      student_id: studentId,
      file_id: assignmentId,
      file: values?.file[0]?.originFileObj,
    }

    dispatch(uploadStudentSubmission(data))
      .then(() => {
        message.success('File uploaded successfully')
        setFileList([])
        dispatch(fetchSubmissions(assignmentId, studentId))
      })
      .catch(() => {
        message.error('Failed to upload the file')
      })
  }

  const handleExpandFeedback = (recordId) => {
    setExpandedFeedback((prevState) => ({
      ...prevState,
      [recordId]: !prevState[recordId], // Toggle expanded state for the feedback
    }))
  }

  const columns = [
    {
      title: 'File Name',
      key: 'submission_file_path',
      render: (_, record) => {
        const fileName = record.submission_file_path.split('/').pop()
        return fileName
      },
    },
    {
      title: 'Download',
      key: 'download',
      render: (_, record) => (
        <Button
          icon={<DownloadOutlined />}
          onClick={() => downloadFile(record.submission_file_path)}
          style={{ backgroundColor: '#005d41', color: 'white' }}
        >
          Download Submission
        </Button>
      ),
    },
    {
      title: 'Grade',
      key: 'grade',
      render: (_, record) => <span>{record.grade || 'Not graded yet'}</span>,
    },
    {
      title: 'Feedback',
      key: 'feedback',
      render: (_, record) => {
        const feedback = record.feedback || 'No feedback yet'
        const isLongFeedback = feedback.length > 100 // Adjust character length as needed
        const isExpanded = expandedFeedback[record.id]

        return (
          <div>
            <Text>
              {isLongFeedback && !isExpanded
                ? `${feedback.substring(0, 100)}...`
                : feedback}
            </Text>
            {isLongFeedback && (
              <Button
                type="link"
                style={{ paddingLeft: 5 }}
                onClick={() => handleExpandFeedback(record.id)}
              >
                {isExpanded ? 'See less' : 'See more'}
              </Button>
            )}
          </div>
        )
      },
    },
  ]

  if (loading) {
    return <Spin tip="Loading submissions..." />
  }

  if (error) {
    return (
      <Alert
        message="Error"
        description={error.message}
        type="error"
        showIcon
      />
    )
  }

  const studentSubmissions = submissions?.[0]?.submissions || []

  return (
    <div>
      <h2>Your Submissions</h2>

      {/* Show upload component if there are no submissions or if studentId is present */}
      {(studentSubmissions[0]?.allow_revision ||
        (studentSubmissions.length === 0 && studentId)) && (
        <Form layout="vertical" form={form} onFinish={handleSubmitAssignment}>
          <Form.Item
            name="file"
            label="Upload File"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[{ required: true, message: 'Please upload the file' }]}
          >
            <Upload
              name={'file'}
              fileList={fileList}
              beforeUpload={() => false}
              maxCount={1} // Limit to a single file
              onChange={handleFileChange}
              onRemove={() => setFileList([])} // Clear file when removed
            >
              <Button icon={<UploadOutlined />}>Upload Assignment</Button>
            </Upload>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginTop: '10px' }}
              disabled={fileList.length === 0}
            >
              Submit Assignment
            </Button>
          </Form.Item>
        </Form>
      )}

      {/* Show submissions table if there are any submissions */}
      {studentSubmissions.length > 0 ? (
        <Table
          dataSource={studentSubmissions}
          columns={columns}
          rowKey="id"
          pagination={false}
        />
      ) : (
        <Empty description="No submissions found" />
      )}
    </div>
  )
}

export default StudentSubmissions
