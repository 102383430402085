// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/App.css */
.layout {
    display: flex;
    flex-direction: column;
}

.header {
    background: #811c6a !important;
    color: white;
    padding: 16px;
}

.content {
    padding: 24px;
    background: #f0f2f5;
}

.footer {
    background: #001529;
    color: white;
    padding: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,8BAA8B;IAC9B,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,aAAa;AACjB","sourcesContent":["/* src/App.css */\n.layout {\n    display: flex;\n    flex-direction: column;\n}\n\n.header {\n    background: #811c6a !important;\n    color: white;\n    padding: 16px;\n}\n\n.content {\n    padding: 24px;\n    background: #f0f2f5;\n}\n\n.footer {\n    background: #001529;\n    color: white;\n    padding: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
