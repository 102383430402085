import React, { useEffect, useState } from 'react'
import {
  Card,
  Row,
  Col,
  Select,
  Spin,
  Alert,
  Button,
  Modal,
  Form,
  Input,
  Tooltip,
} from 'antd'
import { SketchPicker } from 'react-color' // Import SketchPicker for color selection
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchCampuses,
  createCampus,
  deleteCampus,
  updateCampus,
} from './campuses.api' // Adjust the import according to your file structure

const { Meta } = Card
const { Option } = Select

const truncateText = (text, length) => {
  return text?.length > length ? `${text.substring(0, length)}...` : text
}

const CampusCard = () => {
  const [campusesToShow, setCampusesToShow] = useState(6) // Default number of campuses to show
  const [isAddModalVisible, setIsAddModalVisible] = useState(false)
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [editingCampus, setEditingCampus] = useState(null)
  const [color, setColor] = useState('#005d41') // Default color value
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { campuses, loading, error } = useSelector((state) => state.campuses)

  useEffect(() => {
    dispatch(fetchCampuses())
  }, [dispatch])

  const handleSelectChange = (value) => {
    setCampusesToShow(value)
  }

  const handleAddCampus = (values) => {
    dispatch(createCampus({ ...values, color })).then(() => {
      setIsAddModalVisible(false)
      form.resetFields()
      setColor('#005d41') // Reset color to default after submission
      dispatch(fetchCampuses())
    })
  }

  const handleEditCampus = (values) => {
    values.color = color
    dispatch(updateCampus({ id: editingCampus.id, values })).then(() => {
      setIsEditModalVisible(false)
      setEditingCampus(null)
      form.resetFields()
      setColor('#005d41') // Reset color to default after submission
      dispatch(fetchCampuses())
    })
  }

  const handleDeleteCampus = (campusId) => {
    dispatch(deleteCampus(campusId)).then(() => {
      dispatch(fetchCampuses())
    })
  }

  const showEditModal = (campus) => {
    setEditingCampus(campus)
    form.setFieldsValue(campus)
    setColor(campus.color || '#005d41') // Set color to campus color or default
    setIsEditModalVisible(true)
  }

  const handleAddModalCancel = () => {
    setIsAddModalVisible(false)
    form.resetFields()
    setColor('#005d41') // Reset color on cancel
  }

  const handleEditModalCancel = () => {
    setIsEditModalVisible(false)
    setEditingCampus(null)
    form.resetFields()
    setColor('#005d41') // Reset color on cancel
  }

  const handleColorChange = (newColor) => {
    setColor(newColor.hex)
    form.setFieldsValue({ color: newColor.hex })
  }

  if (loading) {
    return <Spin tip="Loading campuses..." />
  }

  if (error) {
    return <Alert message="Error" description={error} type="error" showIcon />
  }

  return (
    <>
      <div
        style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}
      >
        <h2 style={{ margin: 0 }}>Campuses</h2>
        <p style={{ marginLeft: 'auto' }}>
          Show &nbsp;
          <Select
            defaultValue={campusesToShow.toString()}
            onChange={handleSelectChange}
          >
            <Option value="3">3</Option>
            <Option value="6">6</Option>
            <Option value="9">9</Option>
          </Select>
          &nbsp;
          <Button
            style={{ backgroundColor: '#005d41', color: 'white' }}
            type="primary"
            onClick={() => setIsAddModalVisible(true)}
          >
            Add Campus
          </Button>
        </p>
      </div>

      {campuses.length === 0 ? (
        <Alert
          message="No Campuses Available"
          description="There are currently no campuses to display. Please add a new campus."
          type="info"
          showIcon
          style={{ marginBottom: '16px' }}
        />
      ) : (
        <Row gutter={[16, 16]}>
          {campuses.slice(0, campusesToShow).map((campus) => (
            <Col key={campus.id} span={6}>
              <Card
                style={{
                  width: '100%',
                  borderRadius: 20,
                  marginBottom: '20px',
                }}
                cover={
                  <div
                    style={{
                      background: campus.color || '#e0e0e0', // Use campus color or fallback
                      height: '160px',
                      borderTopRightRadius: 20,
                      borderTopLeftRadius: 20,
                      backgroundSize: 'cover',
                    }}
                  ></div>
                }
                actions={[
                  <Button
                    key={'edit'}
                    type="link"
                    onClick={() => showEditModal(campus)}
                  >
                    Edit
                  </Button>,
                  <Button
                    key={'delete'}
                    type="link"
                    danger
                    onClick={() => handleDeleteCampus(campus.id)}
                  >
                    Delete
                  </Button>,
                ]}
              >
                <Meta
                  title={
                    <Tooltip title={campus.name}>
                      {truncateText(campus?.name, 20)}
                    </Tooltip>
                  }
                  description={
                    <Tooltip title={campus.description}>
                      {truncateText(campus?.description, 40)}
                    </Tooltip>
                  }
                />
                <Meta
                  description={
                    <Tooltip title={campus.location}>
                      {truncateText(campus?.location, 40)}
                    </Tooltip>
                  }
                />
              </Card>
            </Col>
          ))}
        </Row>
      )}

      {/* Modal for Adding New Campus */}
      <Modal
        title="Add New Campus"
        visible={isAddModalVisible}
        onCancel={handleAddModalCancel}
        footer={null}
      >
        <Form form={form} onFinish={handleAddCampus} layout="vertical">
          <Form.Item
            name="name"
            label="Campus Name"
            rules={[
              { required: true, message: 'Please input the campus name!' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="location"
            label="Location"
            rules={[
              { required: true, message: 'Please input the campus location!' },
            ]}
          >
            <Input />
          </Form.Item>
          {/* Color Picker for Campus */}
          <Form.Item name="color" label="Campus Color">
            <SketchPicker color={color} onChange={handleColorChange} />
          </Form.Item>
          <Form.Item>
            <Button
              style={{ backgroundColor: '#005d41', color: 'white' }}
              type="primary"
              htmlType="submit"
            >
              Add Campus
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* Modal for Editing Campus */}
      <Modal
        title="Edit Campus"
        visible={isEditModalVisible}
        onCancel={handleEditModalCancel}
        footer={null}
      >
        <Form form={form} onFinish={handleEditCampus} layout="vertical">
          <Form.Item
            name="name"
            label="Campus Name"
            rules={[
              { required: true, message: 'Please input the campus name!' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="location"
            label="Location"
            rules={[
              { required: true, message: 'Please input the campus location!' },
            ]}
          >
            <Input />
          </Form.Item>
          {/* Color Picker for Campus */}
          <Form.Item name="color" label="Campus Color">
            <SketchPicker color={color} onChange={handleColorChange} />
          </Form.Item>
          <Form.Item>
            <Button
              style={{ backgroundColor: '#005d41', color: 'white' }}
              type="primary"
              htmlType="submit"
            >
              Save Changes
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default CampusCard
