import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Select, Checkbox, Button } from 'antd'
import { searchStaffs } from '../../Staffs/staffs.api'
import { addStaffToIntake } from '../intakes.api'

const { Option } = Select

// eslint-disable-next-line react/prop-types
const AddStaffForm = ({ intakeId, intakeDetails, onClose }) => {
  const dispatch = useDispatch()
  const { searchedStaffs } = useSelector((state) => state.staffs)
  const [selectedStaff, setSelectedStaff] = useState(null)
  const [form] = Form.useForm()

  const onSearch = (value) => {
    if (value) {
      dispatch(searchStaffs(value))
    }
  }

  const onSubmit = (values) => {
    if (selectedStaff) {
      dispatch(addStaffToIntake(intakeId, selectedStaff, values.courses))
    }
    onClose()
  }

  return (
    <Form layout="vertical" form={form} onFinish={onSubmit}>
      <Form.Item
        name="search"
        label="Search Staff"
        rules={[
          {
            required: true,
            message: 'Please search and select a staff.',
          },
        ]}
      >
        <Select
          showSearch
          placeholder="Select a staff"
          onSearch={onSearch}
          optionFilterProp="children"
          notFoundContent={null}
          onChange={(value) => setSelectedStaff(value)}
          value={selectedStaff}
        >
          {searchedStaffs?.map((staff) => (
            <Option key={staff.id} value={staff.id}>
              {staff.first_name} {staff.last_name} ({staff.email})
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="courses" label="Select Courses">
        <Checkbox.Group>
          {/* eslint-disable-next-line react/prop-types */}
          {intakeDetails?.courses?.map((course) => (
            <Checkbox key={course.id} value={course.id}>
              {course.name}
            </Checkbox>
          ))}
        </Checkbox.Group>
      </Form.Item>
      <Form.Item>
        <Button
          style={{ backgroundColor: '#005d41', color: 'white' }}
          type="primary"
          htmlType="submit"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}

export default AddStaffForm
