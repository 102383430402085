import { connect } from 'react-redux'
import Login from './views/Login'
import { login, register, logout } from './auth.api'

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  error: state.auth.error,
})

const mapDispatchToProps = (dispatch) => ({
  login: (userData) => dispatch(login(userData)),
  logout: (userData) => dispatch(logout(userData)),
  register: (userData) => dispatch(register(userData)),
})

export const LoginContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login)
