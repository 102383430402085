// Action Creators

// Action Types
export const FETCH_STUDENTS_REQUEST = 'FETCH_STUDENTS_REQUEST'
export const FETCH_STUDENTS_SUCCESS = 'FETCH_STUDENTS_SUCCESS'
export const FETCH_STUDENTS_FAILURE = 'FETCH_STUDENTS_FAILURE'

export const CREATE_STUDENT_REQUEST = 'CREATE_STUDENT_REQUEST'
export const CREATE_STUDENT_SUCCESS = 'CREATE_STUDENT_SUCCESS'
export const CREATE_STUDENT_FAILURE = 'CREATE_STUDENT_FAILURE'

export const UPDATE_STUDENT_REQUEST = 'UPDATE_STUDENT_REQUEST'
export const UPDATE_STUDENT_SUCCESS = 'UPDATE_STUDENT_SUCCESS'
export const UPDATE_STUDENT_FAILURE = 'UPDATE_STUDENT_FAILURE'

export const DELETE_STUDENT_REQUEST = 'DELETE_STUDENT_REQUEST'
export const DELETE_STUDENT_SUCCESS = 'DELETE_STUDENT_SUCCESS'
export const DELETE_STUDENT_FAILURE = 'DELETE_STUDENT_FAILURE'

export const SEARCH_STUDENTS_SUCCESS = 'SEARCH_STUDENTS_SUCCESS'
export const SEARCH_STUDENTS_FAILURE = 'SEARCH_STUDENTS_FAILURE'

export const FETCH_STUDENT_DETAILS_REQUEST = 'FETCH_STUDENT_DETAILS_REQUEST'
export const FETCH_STUDENT_DETAILS_SUCCESS = 'FETCH_STUDENT_DETAILS_SUCCESS'
export const FETCH_STUDENT_DETAILS_FAILURE = 'FETCH_STUDENT_DETAILS_FAILURE'

export const fetchStudentsRequest = () => ({
  type: FETCH_STUDENTS_REQUEST,
})

export const fetchStudentsSuccess = (students) => ({
  type: FETCH_STUDENTS_SUCCESS,
  payload: students,
})

export const fetchStudentsFailure = (error) => ({
  type: FETCH_STUDENTS_FAILURE,
  payload: error,
})

export const fetchStudentDetailsRequest = () => ({
  type: FETCH_STUDENT_DETAILS_REQUEST,
})

export const fetchStudentDetailsSuccess = (student) => ({
  type: FETCH_STUDENT_DETAILS_SUCCESS,
  payload: student,
})

export const fetchStudentDetailsFailure = (error) => ({
  type: FETCH_STUDENT_DETAILS_FAILURE,
  payload: error,
})

export const createStudentRequest = (student) => ({
  type: CREATE_STUDENT_REQUEST,
  payload: student,
})

export const createStudentSuccess = (student) => ({
  type: CREATE_STUDENT_SUCCESS,
  payload: student,
})

export const createStudentFailure = (error) => ({
  type: CREATE_STUDENT_FAILURE,
  payload: error,
})

export const updateStudentRequest = (student) => ({
  type: UPDATE_STUDENT_REQUEST,
  payload: student,
})

export const updateStudentSuccess = (student) => ({
  type: UPDATE_STUDENT_SUCCESS,
  payload: student,
})

export const updateStudentFailure = (error) => ({
  type: UPDATE_STUDENT_FAILURE,
  payload: error,
})

export const deleteStudentRequest = (studentId) => ({
  type: DELETE_STUDENT_REQUEST,
  payload: studentId,
})

export const deleteStudentSuccess = (studentId) => ({
  type: DELETE_STUDENT_SUCCESS,
  payload: studentId,
})

export const deleteStudentFailure = (error) => ({
  type: DELETE_STUDENT_FAILURE,
  payload: error,
})

export const searchStudentsSuccess = (searchedStudents) => ({
  type: SEARCH_STUDENTS_SUCCESS,
  payload: searchedStudents,
})

export const searchStudentsFailure = (error) => ({
  type: SEARCH_STUDENTS_FAILURE,
  payload: error,
})
