import React from 'react'
import { Row, Col, Typography, Card, Form, Input, Button, Checkbox } from 'antd'
import emailjs from 'emailjs-com' // If you use EmailJS, install this library first.

const { Title, Paragraph } = Typography

const ContactUs = () => {
  const [form] = Form.useForm()

  const onFinish = (values) => {
    // Use EmailJS or another email service to send the form data to multiple emails.
    emailjs
      .send(
        'service_z7kica8',
        'template_ink53qv',
        {
          name: values.name,
          email: values.email,
          phone: values.phone,
          message: values.message,
          to_email1: 'info@trenteducation.co.uk',
          to_email2: 'hr@trenteducation.co.uk',
        },
        'YOUR_USER_ID',
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text)
        },
        (err) => {
          console.error('FAILED...', err)
        },
      )
  }

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card
            title={<Title level={3}>Contact Information</Title>}
            bordered={false}
            style={{ backgroundColor: '#f0f2f5', borderRadius: 10 }}
          >
            <Paragraph>
              <strong>Campus Name:</strong> Trent Education Centre
            </Paragraph>
            <Paragraph>
              <strong>Address:</strong> 2.3 Clarendon Park, NG5 1AH
            </Paragraph>
            <Paragraph>
              <strong>Email:</strong> info@trenteducation.co.uk
            </Paragraph>
            <Paragraph>
              <strong>Phone:</strong> +44 115 795 0171
            </Paragraph>
            <Paragraph>
              <strong>Office Hours:</strong> Monday to Friday, 9:00 AM - 5:00 PM
            </Paragraph>
            <Paragraph>
              <strong>Additional Notes:</strong>
              <p>
                Feel free to visit our campus or reach out via phone or email
                for any inquiries. <br />
                We are happy to assist with all your questions regarding
                admissions, academic programs, or campus tours.
              </p>
            </Paragraph>
          </Card>
        </Col>
        <Col span={12}>
          <Card
            title={<Title level={3}>Write Us</Title>}
            bordered={false}
            style={{ backgroundColor: '#f0f2f5', borderRadius: 10 }}
          >
            <Form form={form} onFinish={onFinish} layout="vertical">
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Please input your name!' }]}
              >
                <Input placeholder="Enter your name" />
              </Form.Item>

              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: 'Please input your email!' },
                ]}
              >
                <Input type="email" placeholder="Enter your email" />
              </Form.Item>

              <Form.Item
                name="phone"
                label="Phone Number"
                rules={[
                  {
                    required: true,
                    message: 'Please input your phone number!',
                  },
                ]}
              >
                <Input placeholder="Enter your phone number" />
              </Form.Item>

              <Form.Item
                name="message"
                label="What's on your mind?"
                rules={[
                  { required: true, message: 'Please input your message!' },
                ]}
              >
                <Input.TextArea rows={4} placeholder="Enter your message" />
              </Form.Item>

              <Form.Item name="agreement" valuePropName="checked">
                <Checkbox>
                  I agree to my personal data being stored and used to contact
                  me
                </Checkbox>
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ backgroundColor: '#ff4d4f' }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default ContactUs
