import React from 'react'
import { List, Button, Popconfirm } from 'antd'
import {
  DownloadOutlined,
  FilePdfOutlined,
  FileImageOutlined,
  FileOutlined,
  DeleteOutlined,
  FileSearchOutlined,
} from '@ant-design/icons'
import { Link } from 'react-router-dom'

// eslint-disable-next-line react/prop-types
const AssignmentModal = ({ assignments, onDelete, studentId }) => {
  const downloadFile = (url) => {
    const link = document.createElement('a')
    link.href = url
    link.download = url.split('/').pop()
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const getIcon = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase()
    switch (extension) {
      case 'pdf':
        return <FilePdfOutlined />
      case 'png':
      case 'jpg':
      case 'jpeg':
        return <FileImageOutlined />
      default:
        return <FileOutlined />
    }
  }

  return (
    <List
      itemLayout="horizontal"
      dataSource={assignments}
      renderItem={(item) => (
        <List.Item
          key={item.id}
          actions={[
            <>
              <Button
                key="downloadButton"
                style={{ backgroundColor: '#005d41', color: 'white' }}
                type="primary"
                icon={<DownloadOutlined />}
                onClick={() => downloadFile(item.file_path)}
              >
                Download Assignment
              </Button>
              {studentId && (
                <Link
                  to={`/assignments/${item.id}/student-submissions/${studentId}`}
                  key="submissionsLink"
                >
                  <Button
                    icon={<FileSearchOutlined />}
                    style={{
                      backgroundColor: '#1c87c9',
                      color: 'white',
                      marginLeft: '10px',
                    }}
                  >
                    My Submissions
                  </Button>
                </Link>
              )}
              {!studentId && (
                <>
                  <Link
                    to={`/assignments/${item.id}/submissions`} // Navigate to a new page for student submissions
                    key="submissionsLink"
                  >
                    <Button
                      icon={<FileSearchOutlined />}
                      style={{
                        backgroundColor: '#1c87c9',
                        color: 'white',
                        marginRight: '10px',
                        marginLeft: '10px',
                      }}
                    >
                      View Submissions
                    </Button>
                  </Link>
                  <Popconfirm
                    key="deleteButton"
                    title="Are you sure you want to delete this assignment?"
                    onConfirm={() => onDelete(item.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      type="danger"
                      style={{ backgroundColor: '#cb0000', color: 'white' }}
                      icon={<DeleteOutlined />}
                    >
                      Delete
                    </Button>
                  </Popconfirm>
                </>
              )}
            </>,
          ]}
        >
          <List.Item.Meta
            avatar={getIcon(item.file_name)}
            title={item.file_name}
            description={item.file_path}
          />
        </List.Item>
      )}
    />
  )
}

export default AssignmentModal
