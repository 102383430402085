/* eslint-disable */
import React from 'react'
import { Drawer } from 'antd'
import AddFileForm from './AddFileForm'

const AddFileDrawer = ({
  isDrawerVisible,
  setIsDrawerVisible,
  form,
  handleAddFile,
}) => {
  return (
    <Drawer
      title="Add New File"
      open={isDrawerVisible}
      onClose={() => setIsDrawerVisible(false)}
      footer={null}
      width={600}
    >
      <AddFileForm form={form} handleAddFile={handleAddFile} />
    </Drawer>
  )
}

export default AddFileDrawer
