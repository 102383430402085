import React, { useState, useEffect } from 'react'
import { Form, Input, Button, Typography } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import leftImageLogin from '../../../assets/images/leftImage.jpg' // Ensure this path is correct
import ctpLogo from '../../../assets/images/trentLogo1.png' // Ensure this path is correct

const { Title } = Typography

// eslint-disable-next-line react/prop-types
const Login = ({ login }) => {
  const navigate = useNavigate()
  const [formValues, setFormValues] = useState(null)

  const onFinish = (values) => {
    setFormValues(values)
  }

  useEffect(() => {
    if (formValues) {
      login(formValues)
        .then(() => {
          navigate('/dashboard')
        })
        .catch((error) => {
          console.error('Login failed:', error)
        })
    }
  }, [formValues, login, navigate])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '100vh',
        alignItems: 'center',
        backgroundImage: 'linear-gradient(to right, #0C3A25, #8EC541)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          maxWidth: '1200px',
          backgroundColor: '#fff',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          overflow: 'hidden',
        }}
      >
        <div style={{ flex: 1, background: 'white' }}>
          <img
            src={leftImageLogin}
            alt="Left Illustration"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </div>
        <div style={{ width: '2px', backgroundColor: '#e0e0e0' }} />
        <div style={{ flex: 1, padding: '50px 50px' }}>
          <div style={{ marginBottom: 30, textAlign: 'center' }}>
            <img src={ctpLogo} alt="Focus Logo" style={{ height: 80 }} />
            <Title level={3} style={{ margin: '20px 0' }}>
              Welcome To Virtual Learning Environment
            </Title>
            <p style={{ color: '#888' }}>Login to your account</p>
          </div>
          <Form name="login_form" onFinish={onFinish}>
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Please enter your email!' }]}
            >
              <Input
                style={{ padding: '10px 15px' }}
                prefix={<UserOutlined style={{ color: '#888' }} />}
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: 'Please enter your password!' },
              ]}
            >
              <Input.Password
                style={{ padding: '10px 15px' }}
                prefix={<LockOutlined style={{ color: '#888' }} />}
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item shouldUpdate>
              {({ getFieldsValue }) => {
                const values = getFieldsValue()
                return (
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      width: '100%',
                      fontSize: 20,
                      fontWeight: 'bold',
                      height: 50,
                      backgroundColor: '#005d41',
                      borderColor: '#004990',
                    }}
                    onClick={() => {
                      onFinish(values)
                    }}
                  >
                    Login
                  </Button>
                )
              }}
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default Login
