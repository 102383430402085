import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Button, DatePicker, Select } from 'antd'
import { SketchPicker } from 'react-color'

const { Option } = Select

const AddCourseForm = ({
  form,
  campuses,
  isEditing,
  handleEditCourse,
  handleAddCourse,
}) => {
  const [color, setColor] = useState('#005d41') // Default color value

  // Handle color change and set value in the form
  const handleColorChange = (color) => {
    setColor(color.hex)
    form.setFieldsValue({ color: color.hex })
  }

  return (
    <Form
      form={form}
      onFinish={isEditing ? handleEditCourse : handleAddCourse}
      layout="vertical" // Optional: better spacing for form items
    >
      <Form.Item
        name="name"
        label="Course Name"
        rules={[{ required: true, message: 'Please input the course name!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="description"
        label="Description"
        rules={[
          { required: true, message: 'Please input the course description!' },
        ]}
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        name="campus_id"
        label="Campus"
        rules={[{ required: true, message: 'Please select the campus!' }]}
      >
        <Select>
          {campuses?.map((campus) => (
            <Option key={campus.id} value={campus.id}>
              {campus.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="learning_outcome"
        label="Learning Outcome"
        rules={[
          { required: true, message: 'Please input the learning outcome!' },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="module_outline"
        label="Module Outline"
        rules={[
          { required: true, message: 'Please input the module outline!' },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="start_date"
        label="Start Date"
        rules={[{ required: true, message: 'Please select the start date!' }]}
      >
        <DatePicker />
      </Form.Item>
      <Form.Item
        name="end_date"
        label="End Date"
        rules={[{ required: true, message: 'Please select the end date!' }]}
      >
        <DatePicker />
      </Form.Item>

      {/* Color Picker Item */}
      <Form.Item
        name="color"
        label="Course Color"
        rules={[{ required: true, message: 'Please select a color!' }]}
      >
        <SketchPicker color={color} onChange={handleColorChange} />
      </Form.Item>

      <Form.Item>
        <Button
          style={{ backgroundColor: '#005d41', color: 'white' }}
          type="primary"
          htmlType="submit"
        >
          {isEditing ? 'Update Course' : 'Add Course'}
        </Button>
      </Form.Item>
    </Form>
  )
}

AddCourseForm.propTypes = {
  form: PropTypes.object.isRequired,
  campuses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isEditing: PropTypes.bool.isRequired,
  handleEditCourse: PropTypes.func,
  handleAddCourse: PropTypes.func.isRequired,
}

AddCourseForm.defaultProps = {
  handleEditCourse: () => {},
}

export default AddCourseForm
