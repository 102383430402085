import React, { useEffect, useState } from 'react'
import { Table, Spin, Alert, Button, Space, Drawer, Form } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAdmins, createAdmin, updateAdmin, deleteAdmin } from './admin.api'
import AddAdminForm from './Form/AddAdminForm'

const Admins = () => {
  const dispatch = useDispatch()
  const { loading, admins, error } = useSelector((state) => state.admins)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [currentAdmin, setCurrentAdmin] = useState(null)
  const [form] = Form.useForm()

  useEffect(() => {
    dispatch(fetchAdmins())
  }, [dispatch])

  const handleDelete = (id) => {
    dispatch(deleteAdmin(id))
  }

  const handleAddAdmin = (values) => {
    dispatch(createAdmin(values)).then(() => {
      setDrawerVisible(false)
      form.resetFields()
      dispatch(fetchAdmins())
    })
  }

  const handleEditAdmin = (values) => {
    if (currentAdmin) {
      dispatch(updateAdmin({ id: currentAdmin.id, values })).then(() => {
        setDrawerVisible(false)
        setIsEditing(false)
        setCurrentAdmin(null)
        form.resetFields()
        dispatch(fetchAdmins())
      })
    }
  }

  const handleEditClick = (admin) => {
    setCurrentAdmin(admin)
    form.setFieldsValue(admin)
    setIsEditing(true)
    setDrawerVisible(true)
  }

  const columns = [
    { title: 'First Name', dataIndex: 'first_name', key: 'first_name' },
    { title: 'Last Name', dataIndex: 'last_name', key: 'last_name' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button
            style={{ backgroundColor: '#005d41', color: 'white' }}
            type="primary"
            onClick={() => handleEditClick(record)}
          >
            Edit
          </Button>
          <Button
            type="danger"
            style={{ backgroundColor: '#cb0000', color: 'white' }}
            onClick={() => handleDelete(record.id)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ]

  if (loading) {
    return <Spin tip="Loading admins..." />
  }

  if (error) {
    return (
      <Alert
        message="Error"
        description={error.message}
        type="error"
        showIcon
      />
    )
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h2>Admins</h2>
        <Button
          type="primary"
          style={{ backgroundColor: '#005d41', color: 'white' }}
          onClick={() => {
            setIsEditing(false)
            setCurrentAdmin(null)
            form.resetFields()
            setDrawerVisible(true)
          }}
        >
          Add Admin
        </Button>
      </div>
      <Table columns={columns} dataSource={admins} rowKey="id" />

      <Drawer
        title={isEditing ? 'Edit Admin' : 'Add Admin'}
        width={320}
        onClose={() => {
          setDrawerVisible(false)
          setIsEditing(false)
          setCurrentAdmin(null)
          form.resetFields()
        }}
        visible={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <AddAdminForm
          onSubmit={isEditing ? handleEditAdmin : handleAddAdmin}
          form={form}
        />
      </Drawer>
    </div>
  )
}

export default Admins
