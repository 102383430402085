import React, { useEffect, useState } from 'react'
import {
  Table,
  Select,
  Spin,
  Alert,
  Button,
  Drawer,
  Form,
  Modal,
  Empty,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import {
  fetchModules,
  createModule,
  updateModule,
  deleteModule,
} from './modules.api'
import AddModuleForm from '../Modules/Form/AddModuleForm'

const { Option } = Select

const ModuleCard = () => {
  const [modulesToShow, setModulesToShow] = useState(20) // Control the number of displayed modules
  const [isDrawerVisible, setIsDrawerVisible] = useState(false) // Control the visibility of the drawer
  const [isEditing, setIsEditing] = useState(false) // Check if editing mode is enabled
  const [currentModule, setCurrentModule] = useState(null) // Track the module being edited
  const [form] = Form.useForm() // Ant Design form instance
  const dispatch = useDispatch()
  const { modules, loading, error } = useSelector((state) => state.modules) // Retrieve module state from Redux
  const { courseId } = useParams() // Get courseId from the URL parameters
  const studentId = localStorage.getItem('studentId') // Get studentId from local storage
  const navigate = useNavigate() // React Router navigation function

  // Fetch modules when the courseId changes
  useEffect(() => {
    if (courseId) {
      dispatch(fetchModules(courseId))
    }
  }, [dispatch, courseId])

  // Handle select change for number of modules to show
  const handleSelectChange = (value) => {
    setModulesToShow(parseInt(value, 10)) // Ensure the value is parsed as an integer
  }

  // Handle the edit button click
  const handleEditClick = (e, module) => {
    e.stopPropagation() // Prevent row click if there's any
    form.setFieldsValue({
      name: module.name,
      description: module.description,
    })
    setCurrentModule(module)
    setIsEditing(true)
    setIsDrawerVisible(true)
  }

  // Handle the delete button click
  const handleDeleteModule = (moduleId, e) => {
    e.stopPropagation()
    Modal.confirm({
      title: 'Are you sure you want to delete this module?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        dispatch(deleteModule(moduleId)).then(() => {
          dispatch(fetchModules(courseId))
        })
      },
    })
  }

  // Navigate to module details on name click
  const handleNameClick = (moduleId) => {
    navigate(`/courses/${courseId}/modules/${moduleId}`)
  }

  // Handle adding a new module
  const handleAddModule = (values) => {
    dispatch(createModule({ ...values, course_id: courseId })).then(() => {
      setIsDrawerVisible(false)
      form.resetFields()
      dispatch(fetchModules(courseId))
    })
  }

  // Handle editing an existing module
  const handleEditModule = (values) => {
    if (currentModule) {
      dispatch(
        updateModule({ course_id: courseId, id: currentModule.id, ...values }),
      ).then(() => {
        setIsDrawerVisible(false)
        setIsEditing(false)
        setCurrentModule(null)
        form.resetFields()
        dispatch(fetchModules(courseId))
      })
    }
  }

  // Define columns for the table
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 200, // Adjust the width as needed
      render: (text, record) => (
        <Button
          type="link"
          onClick={(e) => {
            e.stopPropagation()
            handleNameClick(record.id)
          }}
          style={{
            padding: 0,
            fontWeight: 'bold',
            whiteSpace: 'normal', // Allow text to wrap
            wordWrap: 'break-word', // Enable wrapping for long words
          }}
        >
          {text}
        </Button>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text) => (
        <div
          style={{
            display: '-webkit-box',
            WebkitLineClamp: 3, // Limit to 3 lines
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxHeight: '3.5em',
          }}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      ),
    },
    {
      title: 'Actions',
      key: 'action',
      render: (_, record) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            type="primary"
            icon={<EditOutlined />}
            size="small"
            style={{ marginRight: 4 }}
            onClick={(e) => handleEditClick(e, record)}
          />
          <Button
            type="primary"
            danger
            icon={<DeleteOutlined />}
            size="small"
            onClick={(e) => handleDeleteModule(record.id, e)}
          />
        </div>
      ),
    },
  ]

  // Show a loading spinner while fetching modules
  if (loading) {
    return <Spin tip="Loading modules..." />
  }

  // Show an error alert if there's an error fetching modules
  if (error) {
    return <Alert message="Error" description={error} type="error" showIcon />
  }

  return (
    <>
      <div
        style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}
      >
        <h2 style={{ margin: 0 }}>Modules</h2>
        <p style={{ marginLeft: 'auto' }}>
          Show &nbsp;
          <Select
            defaultValue={modulesToShow.toString()}
            onChange={handleSelectChange}
          >
            <Option value="3">3</Option>
            <Option value="6">6</Option>
            <Option value="9">9</Option>
            <Option value="20">20</Option>
            <Option value="30">30</Option>
            <Option value="40">40</Option>
          </Select>
          &nbsp;
          {!studentId && (
            <Button
              type="primary"
              style={{ backgroundColor: '#005d41', color: 'white' }}
              onClick={() => {
                setIsEditing(false)
                setCurrentModule(null)
                form.resetFields()
                setIsDrawerVisible(true)
              }}
            >
              Add Module
            </Button>
          )}
        </p>
      </div>

      {modules.length > 0 ? (
        <Table
          dataSource={modules.slice(0, modulesToShow)} // Show limited modules based on selection
          columns={columns}
          rowKey="id"
          pagination={false}
        />
      ) : (
        <Empty description="No modules available" />
      )}

      {/* Drawer for Adding/Editing Modules */}
      <Drawer
        title={isEditing ? 'Edit Module' : 'Add New Module'}
        visible={isDrawerVisible}
        onClose={() => {
          setIsDrawerVisible(false)
          setIsEditing(false)
          setCurrentModule(null)
          form.resetFields()
        }}
        footer={null}
        width={600}
      >
        <AddModuleForm
          form={form}
          handleAddModule={handleAddModule}
          handleEditModule={handleEditModule}
          isEditing={isEditing}
        />
      </Drawer>
    </>
  )
}

export default ModuleCard
