// Action Creators

// Action Types
export const FETCH_ADMINS_REQUEST = 'FETCH_ADMINS_REQUEST'
export const FETCH_ADMINS_SUCCESS = 'FETCH_ADMINS_SUCCESS'
export const FETCH_ADMINS_FAILURE = 'FETCH_ADMINS_FAILURE'

export const CREATE_ADMIN_REQUEST = 'CREATE_ADMIN_REQUEST'
export const CREATE_ADMIN_SUCCESS = 'CREATE_ADMIN_SUCCESS'
export const CREATE_ADMIN_FAILURE = 'CREATE_ADMIN_FAILURE'

export const UPDATE_ADMIN_REQUEST = 'UPDATE_ADMIN_REQUEST'
export const UPDATE_ADMIN_SUCCESS = 'UPDATE_ADMIN_SUCCESS'
export const UPDATE_ADMIN_FAILURE = 'UPDATE_ADMIN_FAILURE'

export const DELETE_ADMIN_REQUEST = 'DELETE_ADMIN_REQUEST'
export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS'
export const DELETE_ADMIN_FAILURE = 'DELETE_ADMIN_FAILURE'

export const SEARCH_ADMINS_SUCCESS = 'SEARCH_ADMINS_SUCCESS'
export const SEARCH_ADMINS_FAILURE = 'SEARCH_ADMINS_FAILURE'

export const fetchAdminsRequest = () => ({
  type: FETCH_ADMINS_REQUEST,
})

export const fetchAdminsSuccess = (admins) => ({
  type: FETCH_ADMINS_SUCCESS,
  payload: admins,
})

export const fetchAdminsFailure = (error) => ({
  type: FETCH_ADMINS_FAILURE,
  payload: error,
})

export const createAdminRequest = (admin) => ({
  type: CREATE_ADMIN_REQUEST,
  payload: admin,
})

export const createAdminSuccess = (admin) => ({
  type: CREATE_ADMIN_SUCCESS,
  payload: admin,
})

export const createAdminFailure = (error) => ({
  type: CREATE_ADMIN_FAILURE,
  payload: error,
})

export const updateAdminRequest = (admin) => ({
  type: UPDATE_ADMIN_REQUEST,
  payload: admin,
})

export const updateAdminSuccess = (admin) => ({
  type: UPDATE_ADMIN_SUCCESS,
  payload: admin,
})

export const updateAdminFailure = (error) => ({
  type: UPDATE_ADMIN_FAILURE,
  payload: error,
})

export const deleteAdminRequest = (adminId) => ({
  type: DELETE_ADMIN_REQUEST,
  payload: adminId,
})

export const deleteAdminSuccess = (adminId) => ({
  type: DELETE_ADMIN_SUCCESS,
  payload: adminId,
})

export const deleteAdminFailure = (error) => ({
  type: DELETE_ADMIN_FAILURE,
  payload: error,
})

export const searchAdminsSuccess = (searchedAdmins) => ({
  type: SEARCH_ADMINS_SUCCESS,
  payload: searchedAdmins,
})

export const searchAdminsFailure = (error) => ({
  type: SEARCH_ADMINS_FAILURE,
  payload: error,
})
