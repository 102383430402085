import { connect } from 'react-redux'
import ModuleDetails from './ModuleDetails'
import { fetchModuleDetails } from './modules.api'

const mapStateToProps = (state) => ({
  moduleDetails: state.modules.moduleDetails, // Assuming module details are stored separately
  modules: state.modules.modules, // Assuming module details are stored separately
  loading: state.modules.loading,
  error: state.modules.error,
})

const mapDispatchToProps = (dispatch) => ({
  fetchModuleDetails: (moduleId, studentId) =>
    dispatch(fetchModuleDetails(moduleId, studentId)),
})

export const ModuleDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModuleDetails)
