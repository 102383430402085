import { connect } from 'react-redux'
import Admins from './Admins'
import { fetchAdmins, createAdmin, updateAdmin, deleteAdmin } from './admin.api'

const mapStateToProps = (state) => ({
  admins: state.admins.admins,
  loading: state.admins.loading,
  error: state.admins.error,
})

const mapDispatchToProps = (dispatch) => ({
  fetchAdmins: () => dispatch(fetchAdmins()),
  createAdmin: (staffData) => dispatch(createAdmin(staffData)),
  updateAdmin: (staffData) => dispatch(updateAdmin(staffData)),
  deleteAdmin: (staffId) => dispatch(deleteAdmin(staffId)),
})

export const AdminContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Admins)
