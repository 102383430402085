import {
  FETCH_CAMPUSES_REQUEST,
  FETCH_CAMPUSES_SUCCESS,
  FETCH_CAMPUSES_FAILURE,
  CREATE_CAMPUS_REQUEST,
  CREATE_CAMPUS_SUCCESS,
  CREATE_CAMPUS_FAILURE,
  UPDATE_CAMPUS_REQUEST,
  UPDATE_CAMPUS_SUCCESS,
  UPDATE_CAMPUS_FAILURE,
  DELETE_CAMPUS_REQUEST,
  DELETE_CAMPUS_SUCCESS,
  DELETE_CAMPUS_FAILURE,
} from './campuses.actions'

const initialState = {
  campuses: [],
  loading: false,
  error: null,
}

const campusesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CAMPUSES_REQUEST:
    case CREATE_CAMPUS_REQUEST:
    case UPDATE_CAMPUS_REQUEST:
    case DELETE_CAMPUS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_CAMPUSES_SUCCESS:
      return {
        ...state,
        loading: false,
        campuses: action.payload,
        error: null,
      }
    case CREATE_CAMPUS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        campuses: [...state.campuses, action.payload],
      }
    case UPDATE_CAMPUS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        campuses: state.campuses.map((campus) =>
          campus.id === action.payload.id ? action.payload : campus,
        ),
      }
    case DELETE_CAMPUS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        campuses: state.campuses.filter(
          (campus) => campus.id !== action.payload,
        ),
      }
    case FETCH_CAMPUSES_FAILURE:
    case CREATE_CAMPUS_FAILURE:
    case UPDATE_CAMPUS_FAILURE:
    case DELETE_CAMPUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default campusesReducer
