import request from '../../core/request'
import { openNotification } from '../../utils/Notification'
import {
  registerFailure,
  registerSuccess,
  loginSuccess,
  loginFailure,
  logoutSuccess,
} from './auth.actions'
import storage from '../../core/storage'

const saveTokenToLocalStorage = (
  accessToken,
  role,
  studentId,
  staffId,
  adminId,
) => {
  studentId && localStorage.setItem('studentId', studentId)
  staffId && localStorage.setItem('staffId', staffId)
  adminId && localStorage.setItem('adminId', adminId)
  localStorage.setItem('token', accessToken)
  localStorage.setItem('role', role)
  localStorage.setItem('isAuthenticated', true)
}

// Function to handle login
export const login = (userData) => {
  return (dispatch) => {
    return request({
      method: 'POST',
      url: `/users/login`,
      data: userData,
    })
      .then((response) => {
        if (response?.data) {
          console.log('response', response)
          const accessToken = response.data.access_token
          let role
          let staffId
          let studentId
          let adminId
          if (response.data?.staff?.id) {
            role = 'staff'
            staffId = response.data.staff?.id
          } else if (response.data.student?.id) {
            role = 'student'
            studentId = response.data.student?.id
          } else {
            role = 'admin'
            adminId = response.data.admin?.id
          }
          saveTokenToLocalStorage(
            accessToken,
            role,
            studentId,
            staffId,
            adminId,
          )
          dispatch(loginSuccess(response.data))
        } else {
          dispatch(loginFailure(response?.data?.error || 'Login failed'))
          openNotification({
            type: 'error',
            title: 'Login Failed',
            description: response?.data?.error || 'Login failed',
          })
        }
        return response
      })
      .catch((error) => {
        dispatch(loginFailure('An error occurred during login'))
        openNotification({
          type: 'error',
          title: 'Login Error',
          description: `An error occurred during login: ${error.message}`,
        })
      })
  }
}

// Function to handle user registration
export const register = (userData) => {
  return (dispatch) => {
    return request({
      method: 'POST',
      url: '/staffs/register',
      data: userData,
    })
      .then((response) => {
        if (response?.data?.staff) {
          const token = response.data.token
          saveTokenToLocalStorage(token)
          dispatch(registerSuccess(response.data))
        } else {
          dispatch(
            registerFailure(response?.data?.error || 'Registration failed'),
          )
          openNotification({
            type: 'error',
            title: 'Registration Failed',
            description: response?.data?.error || 'Registration failed',
          })
        }
      })
      .catch((error) => {
        dispatch(registerFailure('An error occurred during registration'))
        openNotification({
          type: 'error',
          title: 'Registration Error',
          description: `An error occurred during registration.${error}`,
        })
      })
  }
}

export const logout = () => (dispatch) => {
  storage.clear()
  dispatch(logoutSuccess())
}
