import {
  FETCH_ADMINS_REQUEST,
  FETCH_ADMINS_SUCCESS,
  FETCH_ADMINS_FAILURE,
  CREATE_ADMIN_REQUEST,
  CREATE_ADMIN_SUCCESS,
  CREATE_ADMIN_FAILURE,
  UPDATE_ADMIN_REQUEST,
  UPDATE_ADMIN_SUCCESS,
  UPDATE_ADMIN_FAILURE,
  DELETE_ADMIN_REQUEST,
  DELETE_ADMIN_SUCCESS,
  SEARCH_ADMINS_FAILURE,
  SEARCH_ADMINS_SUCCESS,
  DELETE_ADMIN_FAILURE,
} from './admin.actions'

const initialState = {
  admins: [],
  loading: false,
  error: null,
}

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ADMINS_REQUEST:
    case CREATE_ADMIN_REQUEST:
    case UPDATE_ADMIN_REQUEST:
    case DELETE_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_ADMINS_SUCCESS:
      return {
        ...state,
        loading: false,
        admins: action.payload,
        error: null,
      }
    case CREATE_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        admins: [...state.admins, action.payload],
      }
    case UPDATE_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        admins: state.admins.map((staff) =>
          staff.id === action.payload.id ? action.payload : staff,
        ),
      }
    case DELETE_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        admins: state.admins.filter((staff) => staff.id !== action.payload),
      }
    case SEARCH_ADMINS_SUCCESS:
      return {
        ...state,
        loading: false,
        searchedStaffs: action.payload,
        error: null,
      }
    case SEARCH_ADMINS_FAILURE:
    case FETCH_ADMINS_FAILURE:
    case CREATE_ADMIN_FAILURE:
    case UPDATE_ADMIN_FAILURE:
    case DELETE_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        searchedStaffs: [],
      }
    default:
      return state
  }
}

export default adminReducer
