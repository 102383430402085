/* eslint-disable */
import React from 'react'
import { Modal, Form, Upload, Button } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

const UploadAssignmentModal = ({
  isUploadModalVisible,
  setIsUploadModalVisible,
  form,
  handleUploadAssignment,
}) => {
  return (
    <Modal
      title="Upload Assignment"
      visible={isUploadModalVisible}
      onCancel={() => setIsUploadModalVisible(false)}
      footer={null}
    >
      <Form form={form} onFinish={handleUploadAssignment}>
        <Form.Item
          name="file"
          valuePropName="fileList"
          getValueFromEvent={(e) => (Array.isArray(e) ? e : e && [e.file])}
        >
          <Upload name="file" beforeUpload={() => false} maxCount={1}>
            <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button
            style={{
              backgroundColor: '#005d41',
              color: 'white',
            }}
            type="primary"
            htmlType="submit"
          >
            Upload
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default UploadAssignmentModal
