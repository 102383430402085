// reducers.js
import { DECREMENT, INCREMENT } from './dashboard.actions'

const initialState = {
  counter: 0,
}

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case INCREMENT:
      return {
        ...state,
        counter: state.counter + 1,
      }
    case DECREMENT:
      return {
        ...state,
        counter: state.counter - 1,
      }
    default:
      return state
  }
}

export default dashboardReducer
