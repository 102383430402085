import React, { useEffect } from 'react'
import { Layout } from 'antd'
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom'
import SidebarMenu from './sidebar/SidebarMenu'
import Dashboard from './components/Dashboard/dashboard.container'
import { LoginContainer } from './components/Auth/auth.container'
import { CoursesContainer } from './components/Courses/courses.container'
import { StudentsContainer } from './components/Students/students.container'
import { StaffsContainer } from './components/Staffs/staffs.container'
import { ModulesContainer } from './components/Modules/modules.container'
import './App.css'
import { ModuleDetailsContainer } from './components/Modules/moduleDetails.container'
import ProtectedRoute from './ProtectedRoute'
import { CampusesContainer } from './components/Campuses/campuses.container'
import { AdminContainer } from './components/Admins/admin.container'
import IntakeDetails from './components/Intakes/IntakeDetails'
import useAxiosInterceptor from './core/useAxiosIntercepter'
import ContactUs from './components/ContactUs/ContactUs'
import AssignmentSubmissions from './components/Modules/AssignmentSubmissions'
import StudentSubmissions from './components/Modules/StudentSubmissions'
import StudentDetails from './components/Students/StudentDetails'
import { IntakesContainer } from './components/Intakes/intakes.container'

const { Content } = Layout

const AppContent = () => {
  useAxiosInterceptor()
  const isAuthenticated = Boolean(localStorage.getItem('isAuthenticated'))
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (
      !isAuthenticated &&
      location.pathname !== '/login' &&
      location.pathname !== '/register'
    ) {
      // Redirect to login if the user is not authenticated and trying to access other routes
      navigate('/login')
    }
  }, [isAuthenticated, location.pathname])

  const isAuthPage =
    location.pathname === '/login' || location.pathname === '/register'
  const isDashboardPage = location.pathname === '/dashboard'

  const isStudent = localStorage.getItem('role') === 'student'
  return (
    <Layout style={{ minHeight: '100vh', overflow: 'hidden' }}>
      {isAuthenticated && <SidebarMenu collapsed={false} />}
      <Layout style={{ marginLeft: isAuthenticated ? 256 : 0 }}>
        <Layout
          className="site-layout"
          style={{
            padding: isAuthPage || isDashboardPage ? 0 : '70px',
            backgroundColor: 'rgb(232, 232, 232)',
          }}
        >
          <Content style={{ padding: 0, margin: 0, minHeight: 280 }}>
            <Routes>
              {!isAuthenticated && (
                <>
                  <Route path="*" element={<Navigate to="/login" replace />} />
                  <Route path="/login" element={<LoginContainer />} />
                </>
              )}
              {isAuthenticated && (
                <>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route
                    path="/courses"
                    element={
                      <ProtectedRoute allowedRoles={['admin', 'staff']}>
                        <CoursesContainer />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/students"
                    element={
                      <ProtectedRoute allowedRoles={['admin', 'staff']}>
                        <StudentsContainer />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/students/:studentId"
                    element={
                      <ProtectedRoute allowedRoles={['admin', 'staff']}>
                        <StudentDetails />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/staffs"
                    element={
                      <ProtectedRoute allowedRoles={['admin', 'staff']}>
                        <StaffsContainer />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/campuses"
                    element={
                      <ProtectedRoute allowedRoles={['admin', 'staff']}>
                        <CampusesContainer />
                      </ProtectedRoute>
                    }
                  />
                  {isStudent && (
                    <>
                      <Route
                        path="/intakes/:studentId"
                        element={<IntakesContainer />}
                      />
                      <Route
                        path="/intakes/intakeDetails/:intakeId"
                        element={<IntakeDetails />}
                      />
                    </>
                  )}
                  <Route
                    path="/intakes"
                    element={
                      <ProtectedRoute allowedRoles={['admin', 'staff']}>
                        <IntakesContainer />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admins"
                    element={
                      <ProtectedRoute allowedRoles={['admin', 'staff']}>
                        <AdminContainer />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/assignments/:assignmentId/submissions"
                    element={
                      <ProtectedRoute allowedRoles={['admin', 'staff']}>
                        <AssignmentSubmissions />
                      </ProtectedRoute>
                    }
                  />
                  {isStudent && (
                    <Route
                      path="/assignments/:assignmentId/student-submissions/:studentId"
                      element={
                        <ProtectedRoute allowedRoles={['student']}>
                          <StudentSubmissions />
                        </ProtectedRoute>
                      }
                    />
                  )}
                  <Route
                    path="/intakes/intakeDetails/:intakeId"
                    element={
                      <ProtectedRoute allowedRoles={['admin', 'staff']}>
                        <IntakeDetails />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/contact-us" element={<ContactUs />} />
                  <Route
                    path="/courses/:courseId/modules"
                    element={<ModulesContainer />}
                  />
                  <Route
                    path="/courses/:courseId/modules/:moduleId"
                    element={<ModuleDetailsContainer />}
                  />
                  <Route path="/submissions" element={<Dashboard />} />
                  <Route path="/announcements" element={<Dashboard />} />
                  <Route path="/deadlines" element={<Dashboard />} />
                  <Route
                    path="/unauthorized"
                    element={<div>Unauthorized Access</div>}
                  />
                  <Route
                    path="/courses/:studentId"
                    element={<CoursesContainer />}
                  />
                </>
              )}
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  )
}

const App = () => (
  <BrowserRouter>
    <AppContent />
  </BrowserRouter>
)

export default App
